import React, { FC, useEffect, useState } from 'react';
import jwt_decode from "jwt-decode";
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useGlobalAppDispatch, useGlobalAppState } from './store/app-context';
import { useCookies } from 'react-cookie';
import { AppActions } from './store/reducer';

import Spin from 'antd/lib/spin';
import Layout from 'antd/lib/layout';
import { Token } from './domain/token.interface';

import './App.scss';

import Dashboard from './app/dashboard/Dashboard';
import Stats from './app/stats/Stats';
import { RequestGlobalApiActions } from './store/actions';

import ApgToolbar from '@apg-stats/apg-core/lib/ApgToolbar';
import { Palette } from '@apg-stats/apg-core/lib/css-in-js';
import Tournaments from './app/tournaments/Tournaments';
import { TournamentProvider } from './app/tournaments/store/tournament-context';
import Seasons from './app/seasons/Seasons';
import { SeasonProvider } from './app/seasons/store/season-context';
import { BackTop } from 'antd';
import Players from './app/players/Players';
import { init } from './utils/ga';

const App: FC = () => {
  const { pendingFetches, secondaryMenuLogo, showSecondaryMenu, secondaryMenuItems } = useGlobalAppState();
  const dispatchApp = useGlobalAppDispatch();
  const [cookies] = useCookies();
  const [isReady, setIsReady] = useState(false);

  const history = useHistory();
  const goToUrl = (path: string): void => {
    history.push(path);
  }

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const menuItems =[
    {
      id: '',
      title: 'Temporadas',
      href: '/'
    },
    {
      id: 'champions',
      title: 'Campeones',
      href: '/champions', 
      disabled: true
    }
  ];

  useEffect(() => {
    if (cookies.APGTOKEN) {
      const decodeToken: Token = jwt_decode(cookies.APGTOKEN);

      dispatchApp({ type: AppActions.SetUserToken, payload: decodeToken.user })
    }

    setIsReady(true);

    const getChamps = async () => {
      try {
        await RequestGlobalApiActions.getChamps(dispatchApp);
      } catch (error) {
        // TODO
      }
    };

    getChamps();

    const getCreators = async () => {
      try {
        await RequestGlobalApiActions.getCreators(dispatchApp);
      } catch (error) {
        // TODO
      }
    };

    getCreators();

    const getTeams = async () => {
      try {
        await RequestGlobalApiActions.getTeams(dispatchApp);
      } catch (error) {
        // TODO
      }
    };

    getTeams();
  }, [cookies]);

  useEffect(() => {
    init("G-2MJLBFQDVL");
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {pendingFetches > 0 && (
        <div className="loading-container">
          <Spin />
        </div>
      )}
      <Layout style={{ backgroundColor: Palette.Gray100 }}>
        <BackTop />
        <ApgToolbar
          active={splitLocation[1]}
          showGameLogo={true}
          menuItems={menuItems}
          goToUrl={goToUrl}
          secondaryMenuLogo={secondaryMenuLogo}
          showSecondaryMenu={showSecondaryMenu}
          secondaryMenuItems={secondaryMenuItems.length > 0 ? secondaryMenuItems: []}
        />
        <Switch>
          <Route exact path="/">
            <Dashboard />
          </Route>
          <Route path="/stats">
            <Stats />
          </Route>
          <Route path="/tournaments">
            <TournamentProvider>
              <Tournaments />
            </TournamentProvider>
          </Route>
          <Route path="/seasons">
            <SeasonProvider>
              <Seasons />
            </SeasonProvider>
          </Route>
          <Route path="/players">
            <Players />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </Layout>
    </>
  )
};

export default App;
