import { Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

export interface MatchSetColsInterface {
  top?: string | number | ReactNode,
  icon?: ReactNode,
  bottom?: string | number | ReactNode,
}

const MatchColLeft = styled.div`
  display: flex;
  font-size: ${Spacing.Spacing2};
  justify-content: flex-start;
  width: calc((100% - 3rem) / 2);
`

const MatchColMid = styled.div`
  display: flex;
  justify-content: center;
  width: 3rem;
`

const MatchColRight = styled.div`
  display: flex;
  font-size: ${Spacing.Spacing2};
  justify-content: flex-end;
  width: calc((100% - 3rem) / 2);
`

const MatchSetCols: FC<MatchSetColsInterface> = ({ top, icon, bottom }: MatchSetColsInterface) => {
  return (
    <>
      <MatchColLeft>
        {top}
      </MatchColLeft>
      <MatchColMid>
        {icon}
      </MatchColMid>  
      <MatchColRight>
        {bottom}
      </MatchColRight>
    </>
  )
}

export default MatchSetCols;