import React, { FC, useEffect, useState } from 'react';
import { Table, TablePaginationConfig } from 'antd';
import { useSeasonState } from '../../../store/season-context';
import { RequestSeason } from '../../../proxy/wildrift-season';
import { WrapperResponsive } from '../../../../../domain/styles/ApgStyles.styled';
import { columnsSeasonTeams } from '../../../domain/team.columns';
import { TeamStat } from '../../../../../domain/team.interface';
import { FilterValue } from 'antd/lib/table/interface';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

const FilterInitial = {
  page: 1,
  limit: 32,
  top: false,
  field: 'winRate',
  order: 'descend'
}

const SeasonStatsTeams: FC = () => {
  const history = useHistory();
  const { season } = useSeasonState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teams, setTeams] = useState<TeamStat[]>();
  
  useEffect(() => {
    const getTopTeamsStats = async () => {
      setIsLoading(true)
      try {
        const response = await RequestSeason.getSeasonTeams(season.slug, FilterInitial);
        setTeams(response)
      } finally {
        setIsLoading(false)
      }
    };

    getTopTeamsStats();
  }, [])

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any): void => {
    const field = sorter.field;
    const order = sorter.order === 'ascend' ? 'asc' : 'desc';

    const sortedTeams = _.orderBy(teams, [field], [order]) as TeamStat[];
    setTeams(sortedTeams)
  };

  const goToTeam = (teamSlug: string): void => {
    history.push(`/seasons/${season.slug}/teams/${teamSlug}`);
  }
  
  return (
    <>
      <WrapperResponsive sm={'215%'}>
        <Table
          locale={{ emptyText: 'No hay equipos para mostrar' }} 
          pagination={false}
          rowKey={record => record._id}
          columns={columnsSeasonTeams()}
          dataSource={teams}
          onChange={handleTableChange}
          loading={isLoading}
          bordered={true}
          size={'small'}
          onRow={(record) => {
            return {
              onClick: () => goToTeam(record.slug)
            };
          }}
        />
      </WrapperResponsive>
    </>
  )
};

export default SeasonStatsTeams;