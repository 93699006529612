import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs, Row, Col } from 'antd';
import { CartStyled } from '../../../../domain/styles/Card.styled';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { AppActions } from '../../../../store/reducer';
import { RequestStats } from '../../proxy/stats';
import { ObjetivesInterface } from '../../domain/objetive.interface';
import DrakesTable from './Drakes';
import TeamSidesTable from './TeamSides';
import ExtrasTable from './Extras';

const { TabPane } = Tabs;

enum TabRoutes {
  '/stats/',
  '/stats/champions',
  '/stats/objetives'
}

const Objetives: FC = () => {
  const history = useHistory();
  const dispatchApp = useGlobalAppDispatch();
  const [objetives, setObjetives] = useState<ObjetivesInterface>();

  useEffect(() => {
    const getStatsByLane = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestStats.getObjetiveStats();
        setObjetives(response)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getStatsByLane();
  }, [])

  const goToUrl = (tabNumber: string): void => {
    history.push(TabRoutes[+tabNumber]);
  }

  return (
    <>
      <Tabs defaultActiveKey="2" onTabClick={(tabNumber) => goToUrl(tabNumber)}>
        <TabPane tab="Campeones" key="1"></TabPane>
        <TabPane tab="Objetivos" key="2">
          <Row gutter={[{ xs: 16, sm: 16, md: 16, lg: 16 }, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CartStyled.Container>
                <DrakesTable drakes={objetives?.drakes} />
              </CartStyled.Container>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CartStyled.Container style={{ minHeight: '10rem', marginBottom: '1rem' }}>
                <TeamSidesTable teams={objetives?.sideWins} />
              </CartStyled.Container>
              <CartStyled.Container style={{ minHeight: '10rem' }}>
                <ExtrasTable data={objetives?.miscWinRate} />
              </CartStyled.Container>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </>
  )
};

export default Objetives;
