import React, { FC } from 'react';
import { Table } from 'antd';
import { TableStyled } from '../../../../domain/styles/Table.styled';
import { ItemInterface } from '../../domain/objetive.interface';
import { Icons } from '../../domain/icons';
import { Spacing } from '@apg-stats/apg-core/lib/css-in-js';

interface TeamsInterface {
  teams?: ItemInterface[]
}

const TeamSidesTable: FC<TeamsInterface> = ({ teams }: TeamsInterface) => {
  const columns = [
    {
      title: 'Lado del mapa',
      dataIndex: 'side',
      className: 'text-left',
      render: (text: string, record: ItemInterface)  => {
        const icons = record.icons.map((icon: string) => {
          return Icons(icon, record.key) 
        })

        return (
          <div className="d-flex align-items-center">
            {icons}
            <TableStyled.Text style={{ marginLeft: Spacing.Spacing1 }}>{record.text}</TableStyled.Text>
          </div>
        )
      },
    },
    {
      title: 'Win rate',
      dataIndex: 'winRate',
      className: 'text-center',
      width: 100,
      render: (text: string, record: ItemInterface)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <TableStyled.Text>{record.winRate}</TableStyled.Text>
          </div>
        )
      },
    }
  ];

  return (
    <Table
      locale={{ emptyText: 'No hay estadísticas para mostrar' }} 
      rowKey={record => record.key}
      columns={columns}
      pagination={false}
      dataSource={teams}
      size={'small'}
    />
  )
};

export default TeamSidesTable;
