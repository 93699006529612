import { ChampFilters, ChampPaginate } from "@apg-stats/apg-core/lib/champs";
import { BuildsFilters, BuildPaginate } from "@apg-stats/apg-core/lib/probuilds";
import { ObjetivesInterface } from "../domain/objetive.interface";

export interface RequestError {
  title: string,
  message: string
}

const getError = ({ title, message }: RequestError): RequestError =>  ({ title, message })

const getChampStats = async (filters: ChampFilters): Promise<ChampPaginate> => {
  const searchLane = filters.laneId ? `&laneId=${filters.laneId}` : '';
  const searchChamp = filters.champId ? `&champId=${filters.champId}` : '';
  const field = filters.field ? `&field=${filters.field}` : '';
  const order = filters.order ? `&order=${filters.order}` : '';

  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/stats/champions?page=${filters.page}&limit=${filters.limit}${searchLane}${searchChamp}${field}${order}`, {
    method: 'GET',
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getObjetiveStats = async (): Promise<ObjetivesInterface> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/stats/objetives`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getProBuilds = async (filters: BuildsFilters, token?: string): Promise<BuildPaginate> => {
  const searchLane = filters.laneId ? `&laneId=${filters.laneId}` : '';
  const searchChamp = filters.champId ? `&champId=${filters.champId}` : '';
  const searchBroadCaster = filters.broadcasterId ? `&broadcasterId=${filters.broadcasterId}` : '';

  let headers = {}

  if (token) {
    headers = {
      'Authorization': token
    }
  }

  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/probuilds?page=${filters.page}&limit=${filters.limit}${searchLane}${searchChamp}${searchBroadCaster}`, {
    method: 'GET',
    headers
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

export const RequestStats = {
  getChampStats,
  getObjetiveStats,
  getProBuilds
}