import { css } from "styled-components";

const toRem = (pxValue: number) => {
  const ratio = 16;
  return pxValue / ratio + 'rem';
}

export const FontItalic = css`
  font-style: italic;
`

export const FontBold = css`
  font-weight: 700;
`

export const FontBoldItalic = css`
  font-style: italic;
  font-weight: 700;
`

export const Font10 = css`
  font-size: ${toRem(10)};
  line-height: ${toRem(18)};
`

export const Font12 = css`
  font-size: ${toRem(12)};
  line-height: ${toRem(20)};
`

export const Font14 = css`
  font-size: ${toRem(14)};
  line-height: ${toRem(22)};
`

export const Font16 = css`
  font-size: ${toRem(16)};
  line-height: ${toRem(24)};
`

export const Font20 = css`
  font-size: ${toRem(20)};
  line-height: ${toRem(28)};
`

export const Font24 = css`
  font-size: ${toRem(24)};
  line-height: ${toRem(32)};
`

export const Font38 = css`
  font-size: ${toRem(38)};
  line-height: ${toRem(46)};
`

export const Font46 = css`
  font-size: ${toRem(46)};
  line-height: ${toRem(54)};
`

export const Font54 = css`
  font-size: ${toRem(54)};
  line-height: ${toRem(62)};
`