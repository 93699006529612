import { PlayerFilters, PlayerStat, PlayerStatsPaginate } from '../../../domain/player.interface';

export interface RequestError {
  title: string,
  message: string
}

const getError = ({ title, message }: RequestError): RequestError =>  ({ title, message })

const getStatsPlayers = async (filters: PlayerFilters): Promise<PlayerStatsPaginate> => {
  const searchChamp = filters.champId ? `&champId=${filters.champId}` : '';
  const searchPlayer = filters.playerId ? `&playerId=${filters.playerId}` : '';
  const searchTeam = filters.teamId ? `&teamId=${filters.teamId}` : '';
  const field = filters.field ? `&field=${filters.field}` : '';
  const order = filters.order ? `&order=${filters.order}` : '';

  const filterConcat = `${searchPlayer}${searchChamp}${searchTeam}${field}${order}`;

  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/stats/players?page=${filters.page}&limit=${filters.limit}${filterConcat}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getPlayerProfile = async (slug: string): Promise<PlayerStat> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/players/${slug}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

export const RequestPlayers = {
  getStatsPlayers,
  getPlayerProfile
}