import React, { FC, useState, useEffect } from 'react';
import { Table } from 'antd';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { AppActions } from '../../../../store/reducer';
import { RequestWildRift } from '../../../../proxy/wildrift';
import { TableStyled } from '../../../../domain/styles/Table.styled';
import dateFormat from "dateformat";
import { Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import { TournamentPaginate, TournamentItem } from '@apg-stats/apg-core/lib/tournaments';
import { WrapperResponsive } from '../../../../domain/styles/ApgStyles.styled';
import { useHistory } from 'react-router-dom';

const TournamentsTable: FC = () => {
  const history = useHistory();
  const dispatchApp = useGlobalAppDispatch();
  const [tournaments, setTournaments] = useState<TournamentPaginate>()

  useEffect(() => {
    const getStatsByLane = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestWildRift.getTournaments();
        setTournaments(response)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getStatsByLane();
  }, [])

  const goToUrl = (uri: string): void => {
    history.push(uri);
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center">
            <TableStyled.Image src={record.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
            <TableStyled.TextCaption>{record.name} - {record.season}</TableStyled.TextCaption>
          </div>
        )
      },
    },
    {
      title: 'Fechas',
      dataIndex: 'dates',
      className: 'text-center',
      width: 180,
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <TableStyled.Text>{dateFormat(record.startTime, 'shortDate')} - {dateFormat(record.endTime, 'shortDate')}</TableStyled.Text>
          </div>
        )
      },
    }
  ];

  return (
    <WrapperResponsive>
      <Table
        onRow={(record) => {
          return {
            onClick: () => goToUrl(record.uri)
          };
        }}
        locale={{ emptyText: 'No hay temporadas para mostrar' }} 
        pagination={{ hideOnSinglePage: true }}
        rowKey={record => record.uri}
        columns={columns}
        dataSource={tournaments?.items}
      />
    </WrapperResponsive>
  )
};

export default TournamentsTable;
