import React, { FC, useEffect, useState } from 'react';
import { Layout, Table, TablePaginationConfig, Typography } from 'antd';
import heroBanner from '../../../../assets/images/herobanner-072021.jpg'
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import Section from '@apg-stats/apg-core/lib/ApgSection';
import { Palette } from '@apg-stats/apg-core/lib/css-in-js';
import { FilterValue } from 'antd/lib/table/interface';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { WrapperResponsive, ApgPageHeader } from '../../../../domain/styles/ApgStyles.styled';
import { CartStyled } from '../../../../domain/styles/Card.styled';
import { HeroBannerStyled } from '../../../../domain/styles/HeroBanner.styled';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { AppActions } from '../../../../store/reducer';
import ProBuilds from '../../../dashboard/sections/ProBuilds/ProBuilds';
import { RequestPlayers } from '../../proxy/wildrift-players';
import { PlayerFilters, PlayerStatsPaginate } from '../../../../domain/player.interface';
import { columnsStatsPlayers } from '../../../seasons/domain/player.columns';

const { Content } = Layout;
const { Link } = Typography;

const FilterInitial = {
  page: 1,
  limit: 10,
  top: false,
  field: 'matchPlayed',
  order: 'descend'
}

const PlayersList: FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatchApp = useGlobalAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<PlayerFilters>(FilterInitial);
  const [players, setPlayers] = useState<PlayerStatsPaginate>();
  const title = "Jugadores";

  useEffect(() => {
    const getTopTeamsStats = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestPlayers.getStatsPlayers(searchFilters);
        setPlayers(response)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getTopTeamsStats();
  }, [])

  const getTeams = async(filters: PlayerFilters) => {
    setIsLoading(true);
    try {
      const response = await RequestPlayers.getStatsPlayers(filters);
      setPlayers(response)

      setSearchFilters(filters)
    } finally {
      setIsLoading(false);
    }
  }

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any): void => {
    getTeams({
      ...searchFilters,
      page: pagination.current,
      limit: pagination.pageSize,
      field: sorter.field,
      order: sorter.order
    })
  };

  const goToUrl = (uri: string): void => {
    history.push(uri);
  }
  
  return (
    <>
      <HeroBannerStyled.Page style={{ backgroundImage: `url(${heroBanner})` }}>
        <HeroBannerStyled.Title>{title}</HeroBannerStyled.Title>
      </HeroBannerStyled.Page>
      <Section spacing={'sm'}>
        <Content className="container">
          <CartStyled.Container> 
            {/* <div className={`filters d-flex align-items-center ${isLtMd ? 'flex-column' : ''}`}>
              <Select
                showSearch
                allowClear
                style={{ width: isLtMd ? '100%' : 200, marginRight: isLtMd ? 0 : Spacing.Spacing1, marginBottom: Spacing.Spacing1 }}
                placeholder="Buscar equipo"
                optionFilterProp="children"
                onChange={onSelectTeam}
              >
                {selectTeams.length > 0 && selectTeams.map((team: TeamBase) => (
                  <Option key={team._id} value={team._id}>{team.name}</Option>
                ))}
              </Select>
            </div> */}

            <WrapperResponsive sm={'215%'}>
              <Table
                onRow={(record) => {
                  return {
                    onClick: () => goToUrl(`${url}/${record.slug}`)
                  };
                }}
                locale={{ emptyText: 'No hay equipos para mostrar' }} 
                pagination={{ 
                  hideOnSinglePage: true,
                  current: players?.page,
                  total: players?.totalItems
                }}
                rowKey={record => record._id}
                columns={columnsStatsPlayers()}
                dataSource={players?.items}
                onChange={handleTableChange}
                loading={isLoading}
              />
            </WrapperResponsive>
          </CartStyled.Container>
        </Content>
      </Section>
      <Section style={{ backgroundColor: Palette.DarkBlue600 }} showVolt showVoltBottom={false} spacing={'xl'}>
        <Content className="container">
          <ApgPageHeader
            light={true}
            backIcon={false}
            title={'Pro Builds'}
            extra={
              <Link href='/probuilds'>
                VER MÁS BUILDS
              </Link>
            }
          />

          <ProBuilds />
        </Content>
      </Section>
      <ApgFooter />
    </>
  )
};

export default PlayersList;
