import { DispatchObject } from '@apg-stats/apg-core/lib/dispatch-object';
import produce, { Draft } from 'immer';
import { Dispatch } from 'react';

export type DispatchTournament = Dispatch<DispatchObject<TournamentActions>>;

export enum TournamentActions {
  SetTournament = 'SET_TOURNAMENT',
  ClearData = 'CLEAR_DATA'
}

export interface TournamentBase {
  name: string,
  slug: string,
  colors: string,
  countryCode: string,
  logoUrl: string,
  bannerUrl: string,
  bannerMobileUrl: string,
  seasonID: string,
  status: string,
  contestantType: string,
  startTime: string,
  endTime: string,
  mode: string,
  uri: string,
  prizepool: number,
  currentSeason: number,
  currentStage: string
}

export interface TournamentState {
  tournament: TournamentBase;
  error: null;
}

export const tournamentInitialState: TournamentState = {
  tournament: {
    name: '',
    slug: '',
    colors: '',
    countryCode: '',
    logoUrl: '',
    bannerUrl: '',
    bannerMobileUrl: '',
    currentSeason: 1,
    seasonID: '',
    status: '',
    contestantType: '',
    startTime: '',
    endTime: '',
    mode: '',
    uri: '',
    prizepool: 0,
    currentStage: ''
  },
  error: null,
};

export const appReducer = produce((draft: Draft<TournamentState>, { type, payload }: DispatchObject<TournamentActions>) => {
  switch (type) {
    case TournamentActions.SetTournament:
      draft.tournament = payload;
      break;
    case TournamentActions.ClearData:
      return tournamentInitialState;
    default:
      throw new Error(`Unknown global action type: ${type}`);
  }
});
