import { MediaQuery, Palette } from "@apg-stats/apg-core/lib/css-in-js"
import Breadcrumb from "antd/lib/breadcrumb"
import Sider from "antd/lib/layout/Sider"
import styled from "styled-components"

const ApgSider = styled(Sider)`
  height: calc(100vh - 4rem);
  left: 0;
  max-width: 16.875rem;
  position: fixed;
  width: 16.875rem;
  
  .ant-menu {
    background-color: ${Palette.Gray200};
  }

  .ant-layout-sider-zero-width-trigger {
    display: none;
  }

  ${MediaQuery.LessThanLarge} {
    display: none;
  }
`

const ApgBreadcrumb = styled(Breadcrumb)`
  display: flex;

  & > span {
    align-items: center;
    display: flex;
  }
`

export const TournamentStyled = {
  ApgSider,
  ApgBreadcrumb
}