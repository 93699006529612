import { TeamPlayerStats, TeamStat } from '../../../domain/team.interface';

export interface RequestError {
  title: string,
  message: string
}

const getError = ({ title, message }: RequestError): RequestError =>  ({ title, message })

const getTeamStats = async (seasonSlug: string, teamSlug: string): Promise<TeamStat> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonSlug}/teams/${teamSlug}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getTeamPlayersStats = async (seasonSlug: string, teamSlug: string): Promise<TeamPlayerStats[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonSlug}/teams/${teamSlug}/players`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

export const RequestSeasonTeams = {
  getTeamStats,
  getTeamPlayersStats
}