import { castDraft } from 'immer';
import React, { createContext, FC, ReactNode, useReducer } from 'react';
import { appReducer, SeasonState, DispatchSeason, seasonInitialState } from './reducer';

export interface SeasonProviderInterface {
  children: ReactNode;
  testStateProps?: Partial<SeasonState>;
  testDispatch?: DispatchSeason;
}

const SeasonStateContext = createContext<SeasonState | undefined>(undefined);
const SeasonDispatchContext = createContext<DispatchSeason | undefined>(undefined);

const SeasonProvider: FC<SeasonProviderInterface> = ({ children, testStateProps, testDispatch }: SeasonProviderInterface) => {
  const [state, dispatch] = useReducer(appReducer, seasonInitialState);

  return (
    <SeasonStateContext.Provider value={{ ...castDraft(state), ...testStateProps }}>
      <SeasonDispatchContext.Provider value={testDispatch || dispatch}>{children}</SeasonDispatchContext.Provider>
    </SeasonStateContext.Provider>
  );
};

const useSeasonState = (): SeasonState => {
  const context = React.useContext(SeasonStateContext) as SeasonState;

  if (context === undefined) {
    throw new Error('useSeasonState must be used within a SeasonStateContext');
  }

  return context;
};

const useSeasonDispatch = (): DispatchSeason => {
  const context = React.useContext(SeasonDispatchContext) as DispatchSeason;

  if (context === undefined) {
    throw new Error('useDispatchSeasonDispatch must be used within a SeasonDispatchContext');
  }

  return context;
};

export { SeasonProvider, useSeasonState, useSeasonDispatch };
