import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Player from './pages/Player/Player';
import PlayersList from './pages/PlayersList/PlayerList';

const Players: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PlayersList />
      </Route>
      <Route path={`${path}/:playerSlug`}>
        <Player />
      </Route>
      <Redirect from="*" to={`${path}`} />
    </Switch>
  )
};

export default Players;
