import { Statistic } from "antd"
import styled from "styled-components"

export const OverviewStatistic = styled(Statistic)`
  .ant-statistic-content {
    align-items: center;
    display: flex;
  }

  .ant-statistic-content-prefix {
    display: flex;
  }

  .ant-statistic-content-value {
    font-size: 1rem;
  }
`