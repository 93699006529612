import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row, Table } from 'antd';
import { useGlobalAppDispatch } from "../../../../store/app-context";
import { AppActions } from "../../../../store/reducer";
import { useSeasonState } from "../../store/season-context";
import { CartStyled } from "../../../../domain/styles/Card.styled";
import IconKills from '@apg-stats/apg-core/lib/IconKills';
import { Palette } from "@apg-stats/apg-core/lib/css-in-js";
import { FlagOutlined, AimOutlined, CheckCircleOutlined, FireOutlined } from "@ant-design/icons";
import { CustomTitle, WrapperResponsive } from "../../../../domain/styles/ApgStyles.styled";
import { OverviewStatistic } from "../Overview/Overview.styled";
import { ChampRoleImage, ChampThumb } from "./Player.styled";
import { columnsPick } from '../../domain/season.columns';
import { RequestSeasonPlayer } from "../../proxy/wildrift-season-players";
import { TeamPlayerStats } from "../../../../domain/team.interface";
import { LaneIcons } from "../../../../domain/lane-icons";

interface ParamTypes {
  playerSlug: string
}

const SeasonPlayer: FC = () => {
  const history = useHistory();
  const { playerSlug } = useParams<ParamTypes>();
  const { season } = useSeasonState();
  const dispatchApp = useGlobalAppDispatch();
  const [player, setPlayer] = useState<TeamPlayerStats>();

  const getSeasonPlayer = async (): Promise<void> => {
    dispatchApp({ type: AppActions.IsFetching });
    try {
      const champion = await RequestSeasonPlayer.getPlayerStats(season.slug, playerSlug);
      setPlayer(champion);
    } finally {
      dispatchApp({ type: AppActions.FinishedFetching });
    }
  }

  useEffect(() => {
    getSeasonPlayer()
  }, [playerSlug]);

  const goToChamp = (championSlug: string, role: string): void => {
    history.push(`/seasons/${season.slug}/champions/${championSlug}?role=${role}`);
  }

  return (
    <>
    {player && (
      <>
        <CartStyled.Container noMinHeight>
          <CustomTitle $boldItalic level={4}>{player.name}</CustomTitle>

          <div className="d-flex align-items-start justify-content-start gap-3 flex-column flex-md-row">

            <div className="champ-avatar position-relative">
              <ChampThumb src={player.profilePic} alt={player.name} />
              <ChampRoleImage>
                {player.role.slug ? LaneIcons(player.role.slug) : ''}
              </ChampRoleImage>
            </div>

            <Row gutter={[16, 16]} style={{ flex: 1 }}>
             <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Equipo en el torneo"
                  value={player.team.name}
                  prefix={<img src={player.team.logoUrl} style={{ width: '1.5rem' }} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Partidas Ganadas"
                  value={`${player.wins}/${player.picks}`}
                  prefix={<FlagOutlined style={{ fontSize: '1.5rem', color: Palette.Gray900 }} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="K/D/A"
                  value={`${player.kills}/${player.deaths}/${player.assistants}`}
                  prefix={<IconKills style={{ fontSize: '1.5rem', color: Palette.Yellow }} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Win Rate"
                  value={`${player.winRate.toFixed(2)}`}
                  prefix={<CheckCircleOutlined style={{ fontSize: '1.5rem' , color: Palette.SkyBlue}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="KDA"
                  value={`${player.kda.toFixed(2)}`}
                  prefix={<AimOutlined style={{ fontSize: '1.5rem' , color: Palette.Gray800}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="WinRate"
                  value={`${player.winRate.toFixed(2)}%`}
                  prefix={<FireOutlined style={{ fontSize: '1.5rem' , color: Palette.Success}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Kill Participation"
                  value={`${player.killParticipation.toFixed(2)}%`}
                  prefix={<FireOutlined style={{ fontSize: '1.5rem' , color: Palette.SkyBlue}} />}
                />
              </Col>
            </Row>
          </div>
        </CartStyled.Container>

        <CartStyled.Container noMinHeight>
          <CustomTitle $boldItalic level={4}>Campeones</CustomTitle>

          <WrapperResponsive sm={'200%'}>
            <Table
              locale={{ emptyText: 'No hay estadísticas para mostrar' }} 
              pagination={{ hideOnSinglePage: true }}
              rowKey={record => record._id}
              columns={columnsPick()}
              dataSource={player.champs}
              size={'small'}
              onRow={(record) => {
                return {
                  onClick: () => player.role.slug ? goToChamp(record.slug, player.role.slug) : null
                };
              }}
            />
          </WrapperResponsive>
        </CartStyled.Container> 
      </>
    )}
    </>
  )
}

export default SeasonPlayer;