import React, { FC, useEffect, useState } from 'react';
import { Col, Layout, Row, Table, Tabs } from 'antd';
import heroBanner from '../../../../assets/images/herobanner-072021.jpg'
import { BarChartOutlined, AimOutlined } from '@ant-design/icons';
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import Section from '@apg-stats/apg-core/lib/ApgSection';
import IconBaron from '@apg-stats/apg-core/lib/IconBaron';
import IconHerald from '@apg-stats/apg-core/lib/IconHerald';
import IconKills from '@apg-stats/apg-core/lib/IconKills';
import { useParams } from 'react-router-dom';
import { CustomTitle, WrapperResponsive } from '../../../../domain/styles/ApgStyles.styled';
import { CartStyled } from '../../../../domain/styles/Card.styled';
import { HeroBannerStyled } from '../../../../domain/styles/HeroBanner.styled';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { AppActions } from '../../../../store/reducer';
import { OverviewStatistic } from '../../../seasons/pages/Overview/Overview.styled';
import { RequestPlayers } from '../../proxy/wildrift-players';
import { PlayerStat } from '../../../../domain/player.interface';
import { playerChampsColumns } from '../../../stats/domain/champion-columns';

const { Content } = Layout;
const { TabPane } = Tabs;

interface ParamTypes {
  playerSlug: string
}
const Player: FC = () => {
  const dispatchApp = useGlobalAppDispatch();
  const { playerSlug } = useParams<ParamTypes>();
  const [player, setPlayer] = useState<PlayerStat>();
  const [title, setTitle] = useState<string>("Cargando datos del equipo...");

  useEffect(() => {
    const getTeamProfile = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestPlayers.getPlayerProfile(playerSlug);
        setPlayer(response);
        setTitle(response.name);
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getTeamProfile();
  }, [])
  
  return (
    <>
      <HeroBannerStyled.Profile style={{ backgroundImage: `url(${player?.team.bannerUrl ? player?.team.bannerUrl : heroBanner})` }}>
        <Content className="container d-flex align-items-center" style={{ zIndex: 2 }}>
          <HeroBannerStyled.Logo><img src={player?.profilePic} alt={title} /></HeroBannerStyled.Logo>
          <HeroBannerStyled.Title className="title">{title}</HeroBannerStyled.Title>
        </Content>
      </HeroBannerStyled.Profile>
      <Section spacing={'sm'}>
        <Content className="container">
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={16} lg={20}>
              <CartStyled.Container noMinHeight>
                <CustomTitle $boldItalic level={4}>Generales</CustomTitle>

                <Row gutter={[16, 16]}>
                  <Col xs={12} sm={8} md={8} lg={4}>
                    <OverviewStatistic title="Win rate" value={`${player?.winRate}%`} prefix={<BarChartOutlined />} />
                  </Col>
                  <Col xs={12} sm={8} md={8} lg={4}>
                    <OverviewStatistic title="KDA" value={player?.kda} prefix={<AimOutlined />} />
                  </Col>
                  <Col xs={12} sm={8} md={8} lg={4}>
                    <OverviewStatistic title="Kills" value={player?.kills} prefix={<IconKills />} />
                  </Col>
                  <Col xs={12} sm={8} md={8} lg={4}>
                    <OverviewStatistic title="Muertes" value={player?.deaths} prefix={<AimOutlined />} />
                  </Col>
                  <Col xs={12} sm={8} md={8} lg={4}>
                    <OverviewStatistic title="Asistencias" value={player?.assistants} prefix={<IconHerald />} />
                  </Col>
                  <Col xs={12} sm={8} md={8} lg={4}>
                    <OverviewStatistic title="KP" value={`${player?.killParticipation}%`} prefix={<IconBaron />} />
                  </Col>
                </Row>
              </CartStyled.Container>
            </Col>
            <Col xs={24} sm={24} md={8} lg={4}>
              <CartStyled.Container noMinHeight>
                <CustomTitle $boldItalic level={4}>Redes</CustomTitle>
              </CartStyled.Container>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1">
            <TabPane tab="Campeones jugados" key="1">
              <CartStyled.Container noMinHeight>
                <WrapperResponsive sm={'215%'}>
                  <Table
                    locale={{ emptyText: 'No hay partidos para mostrar' }} 
                    pagination={{ 
                      hideOnSinglePage: true,
                      defaultPageSize: 10
                    }}
                    rowKey={record => record._id}
                    columns={playerChampsColumns()}
                    dataSource={player?.champs}
                  />
                </WrapperResponsive>
              </CartStyled.Container>
            </TabPane>
          </Tabs>
        </Content>
      </Section>
      <ApgFooter />
    </>
  )
};

export default Player;
