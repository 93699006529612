import { castDraft } from 'immer';
import React, { createContext, FC, ReactNode, useReducer } from 'react';
import { appReducer, TournamentState, DispatchTournament, tournamentInitialState } from './reducer';

export interface TournamentProviderInterface {
  children: ReactNode;
  testStateProps?: Partial<TournamentState>;
  testDispatch?: DispatchTournament;
}

const TournamentStateContext = createContext<TournamentState | undefined>(undefined);
const TournamentDispatchContext = createContext<DispatchTournament | undefined>(undefined);

const TournamentProvider: FC<TournamentProviderInterface> = ({ children, testStateProps, testDispatch }: TournamentProviderInterface) => {
  const [state, dispatch] = useReducer(appReducer, tournamentInitialState);

  return (
    <TournamentStateContext.Provider value={{ ...castDraft(state), ...testStateProps }}>
      <TournamentDispatchContext.Provider value={testDispatch || dispatch}>{children}</TournamentDispatchContext.Provider>
    </TournamentStateContext.Provider>
  );
};

const useTournamentState = (): TournamentState => {
  const context = React.useContext(TournamentStateContext) as TournamentState;

  if (context === undefined) {
    throw new Error('useTournamentState must be used within a TournamentStateContext');
  }

  return context;
};

const useTournamentDispatch = (): DispatchTournament => {
  const context = React.useContext(TournamentDispatchContext) as DispatchTournament;

  if (context === undefined) {
    throw new Error('useDispatchTournamentDispatch must be used within a TournamentDispatchContext');
  }

  return context;
};

export { TournamentProvider, useTournamentState, useTournamentDispatch };
