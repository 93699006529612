import styled from "styled-components";
import { MediaQuery, Palette, Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import { Font14, Font38, FontBold, FontItalic, FontBoldItalic } from "./ApgTypography.styled";
import { PageHeader, Typography } from 'antd';
import { TitleProps } from "antd/lib/typography/Title";

const { Title } = Typography;

interface ApgPageHeaderInterface {
  light?: boolean
}

export const ApgPageHeader = styled(PageHeader)<ApgPageHeaderInterface>`
  text-transform: uppercase;
  
  .ant-page-header-heading {
    align-items: center;
  }

  .ant-page-header-heading-title {
    color: ${Palette.DarkBlue900};
    ${FontBoldItalic}
    ${Font38}

    ${({ light }) => light && `
      color: ${Palette.Gray100};
    `}
  }

  .ant-page-header-heading-extra {
    ${FontBold}
    ${Font14}
    
    a {
      color: ${Palette.DarkBlue600};

      ${({ light }) => light && `
        color: ${Palette.Gray100} !important;
      `}
    }
  }
`

interface WrapperResponsiveInterface {
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
}

export const WrapperResponsive = styled.div<WrapperResponsiveInterface>`
  .ant-table {
    overflow-x: auto;

    ${MediaQuery.LessThanLarge} {
      .ant-table-container {
        width: ${({ lg }) => lg || "130%"};
      }
    }

    ${MediaQuery.LessThanMedium} {
      .ant-table-container {
        width: ${({ md }) => md || "150%"};
      }
    }

    ${MediaQuery.LessThanSmall} {
      .ant-table-container {
        width: ${({ sm }) => sm || "250%"};
      }
    }
  }

  tr {
    cursor: pointer;
  }
`

interface CustomTitleInterface extends TitleProps {
  side?: string,
  $margin?: string,
  $bold?: boolean,
  $italic?: boolean,
  $boldItalic?: boolean,
}

export const CustomTitle = styled(Title)<CustomTitleInterface>`
  margin-bottom: 0;
  padding: 0;
  text-transform: uppercase;

  ${({ side }) => side === 'home' && `
    text-align: left;
  `}

  ${({ side }) => side === 'away' && `
    text-align: right;
  `}

  ${({ $margin }) => $margin && `
    margin: ${$margin} !important;
  `}

  ${({ $bold }) => $bold && `
    ${FontBold}
  `}

  ${({ $italic }) => $italic && `
    ${FontItalic}
  `}

  ${({ $boldItalic }) => $boldItalic && `
    ${FontBoldItalic}
  `}

  ${MediaQuery.LessThanMedium} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${Spacing.Spacing10};
  }

  span {
    font-weight: normal !important;
    ${Font14}
  }
`