import { DispatchObject } from '@apg-stats/apg-core/lib/dispatch-object';
import produce, { Draft } from 'immer';
import { Dispatch } from 'react';

export type DispatchSeason = Dispatch<DispatchObject<SeasonActions>>;

export enum SeasonActions {
  SetSeason = 'SET_SEASON',
  SetSeasonTeams = 'SET_SEASON_TEAMS',
  ClearData = 'CLEAR_DATA'
}

export interface SeasonBase {
  _id: string,
  name: string,
  slug: string,
  colors: string,
  countryCode: string,
  logoUrl: string,
  bannerUrl: string,
  bannerMobileUrl: string,
  seasonID: string,
  status: string,
  contestantType: string,
  startTime: string,
  endTime: string,
  mode: string,
  uri: string,
  prizepool: number,
  currentSeason: string,
  currentStage: string,
  leagueId: string
}

export interface SeasonTeam {
  _id: string,
  name: string,
  logoUrl: string
}

export interface SeasonState {
  season: SeasonBase;
  teams: SeasonTeam[];
  error: null;
}

export const seasonInitialState: SeasonState = {
  season: {
    _id: '',
    name: '',
    slug: '',
    colors: '',
    countryCode: '',
    logoUrl: '',
    bannerUrl: '',
    bannerMobileUrl: '',
    currentSeason: 'Temporada #1',
    seasonID: '',
    status: '',
    contestantType: '',
    startTime: '',
    endTime: '',
    mode: '',
    uri: '',
    prizepool: 0,
    currentStage: '',
    leagueId: ''
  },
  teams: [],
  error: null,
};

export const appReducer = produce((draft: Draft<SeasonState>, { type, payload }: DispatchObject<SeasonActions>) => {
  switch (type) {
    case SeasonActions.SetSeason:
      draft.season = payload as SeasonBase;
      break;
    case SeasonActions.SetSeasonTeams:
      draft.teams = payload as SeasonTeam[];
      break;
    case SeasonActions.ClearData:
      return seasonInitialState;
    default:
      throw new Error(`Unknown global action type: ${type}`);
  }
});
