import styled from "styled-components";
import { Palette } from "@apg-stats/apg-core/lib/css-in-js";

const HeroBanner = styled.div`
  background-size: cover;
  background-position: center center;
  border-bottom: 2px solid ${Palette.DarkBlue600};
  display: flex;
  justify-content: center;
  margin: 4rem 0 0;
  position: relative;

  &:before {
    background-color: ${Palette.Opacity50};
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

const HeroImage = styled.div`
  padding-bottom: 2rem;
  padding-top: 2rem;
  z-index: 2;
`

export const DashboardStyled = {
  HeroBanner,
  HeroImage
}