import { Palette, Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import { ColumnsType } from "antd/lib/table/interface";
import { TableStyled } from "../../../domain/styles/Table.styled";
import { TeamStat } from "../../../domain/team.interface";
import ApgIcon from '@apg-stats/apg-core/lib/ApgIcon';
import IconTurretBlue from "@apg-stats/apg-core/lib/IconTurretBlue";
import IconTurretRed from "@apg-stats/apg-core/lib/IconTurretRed";
import { SeasonStageGroup } from "./stages";
import IconHerald from "@apg-stats/apg-core/lib/IconHerald";
import IconBaron from "@apg-stats/apg-core/lib/IconBaron";

export const columnsTeams = (): ColumnsType<TeamStat> => [
  {
    title: 'Nombre',
    dataIndex: 'team',
    width: 32,
    render: (text: string, record: TeamStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Win Rate',
    dataIndex: 'winRate',
    className: 'text-center',
    width: 30,
    render: (text: string, record: TeamStat)  => {
      return `${record.winRate}%`
    },
  },
  {
    title: 'Kills',
    dataIndex: 'totalKills',
    className: 'text-center',
    width: 30,
    render: (text: string, record: TeamStat)  => {
      return record.totalKills
    },
  }
];

export const columnsSeasonTeams = (): ColumnsType<TeamStat> => [
  {
    title: 'Nombre',
    dataIndex: 'team',
    render: (text: string, record: TeamStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Win Rate',
    dataIndex: 'winRate',
    className: 'text-center',
    sorter: true,
    width: 120,
    showSorterTooltip: {
      title: "Win Rate"
    },
    render: (text: string, record: TeamStat)  => {
      return `${record.winRate}%`
    },
  },
  {
    title: 'PG/PJ',
    dataIndex: 'wins',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Partidas ganadas / Partidas jugadas"
    },
    render: (text: string, record: TeamStat)  => {
      return `${record.wins}/${record.matchPlayed}`
    },
  },
  {
    title: <ApgIcon icon={IconTurretBlue} size={18} alt="Lado azul" />,
    dataIndex: 'blueWins',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Lado azul"
    },
    render: (text: string, record: TeamStat)  => {
      return `${record.blueWins}%`
    },
  },
  {
    title: <ApgIcon icon={IconTurretRed} size={18} alt="Lado rojo" />,
    dataIndex: 'redWins',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Lado rojo"
    },
    render: (text: string, record: TeamStat)  => {
      return `${record.redWins}%`
    },
  },
  {
    title: <IconBaron style={{ fontSize: '1.25rem' , color: Palette.Baron}} />,
    dataIndex: 'totalBarons',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Baron Nashor"
    },
    render: (text: string, record: TeamStat)  => {
      return `${record.baronsWins}/${record.totalBarons}`
    },
  },
  {
    title: <IconHerald style={{ fontSize: '1.25rem' , color: Palette.Herald}} />,
    dataIndex: 'totalHeralds',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Heraldo de la Grieta"
    },
    render: (text: string, record: TeamStat)  => {
      return `${record.heraldsWins}/${record.totalHeralds}`
    },
  },
  {
    title: 'Kills',
    dataIndex: 'totalKills',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Kills totales"
    },
    render: (text: string, record: TeamStat)  => {
      return record.totalKills
    },
  }
];

export const columnsStatsTeams = (): ColumnsType<TeamStat> => [
  {
    title: 'Nombre',
    dataIndex: 'team',
    render: (text: string, record: TeamStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Win Rate',
    dataIndex: 'winRate',
    className: 'text-center',
    sorter: true,
    width: 120,
    render: (text: string, record: TeamStat)  => {
      return `${record.winRate}%`
    },
  },
  {
    title: 'Wins',
    dataIndex: 'wins',
    className: 'text-center',
    width: 90,
    render: (text: string, record: TeamStat)  => {
      return record.wins
    },
  },
  {
    title: <ApgIcon icon={IconTurretBlue} size={18} alt="Lado azul" />,
    dataIndex: 'blueWins',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: TeamStat)  => {
      return `${record.blueWins}%`
    },
  },
  {
    title: <ApgIcon icon={IconTurretRed} size={18} alt="Lado rojo" />,
    dataIndex: 'redWins',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: TeamStat)  => {
      return `${record.redWins}%`
    },
  },
  {
    title: 'Kills',
    dataIndex: 'totalKills',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: TeamStat)  => {
      return record.totalKills
    },
  },
  {
    title: 'PJ',
    dataIndex: 'matchPlayed',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: TeamStat)  => {
      return record.matchPlayed
    },
  }
];

export const columnsTeamsGroup = (): ColumnsType<SeasonStageGroup> => [
  {
    title: 'Nombre',
    dataIndex: 'team',
    render: (text: string, record: SeasonStageGroup)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'PG',
    dataIndex: 'wins',
    className: 'text-center',
    width: 70,
    render: (text: string, record: SeasonStageGroup)  => {
      return record.wins
    },
  },
  {
    title: 'PP',
    dataIndex: 'losses',
    className: 'text-center',
    width: 70,
    render: (text: string, record: SeasonStageGroup)  => {
      return record.losses
    },
  },
  {
    title: 'PJ',
    dataIndex: 'total',
    className: 'text-center',
    width: 70,
    render: (text: string, record: SeasonStageGroup)  => {
      return record.total
    },
  }
];