import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Select, Table, TablePaginationConfig, Tabs } from 'antd';

import { CartStyled } from '../../../../domain/styles/Card.styled';
import { ChampionStyled } from './Champions.styled';

import { Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import ApgIcon from '@apg-stats/apg-core/lib/ApgIcon';
import IconLaneMid from '@apg-stats/apg-core/lib/IconLaneMid';
import IconLaneTop from '@apg-stats/apg-core/lib/IconLaneTop';
import IconLaneJungle from '@apg-stats/apg-core/lib/IconLaneJungle';
import IconLaneBottom from '@apg-stats/apg-core/lib/IconLaneBottom';
import IconLaneSupport from '@apg-stats/apg-core/lib/IconLaneSupport';
import { Champ, ChampFilters, ChampPaginate } from '@apg-stats/apg-core/lib/champs';

import { useGlobalAppDispatch, useGlobalAppState } from '../../../../store/app-context';
import { AppActions } from '../../../../store/reducer';
import { RequestStats } from '../../proxy/stats';
import { WrapperResponsive } from '../../../../domain/styles/ApgStyles.styled';
import useWindowSize from '@apg-stats/apg-core/lib/useWindowSize';
import { champsColumns } from '../../domain/champion-columns';
import { FilterValue } from 'antd/lib/table/interface';

const { Option } = Select;

const options = [
  { value: '60a588d8fea3cb09902d778f', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneTop} alt="Solo lane" /> <span style={{ marginLeft: '.5rem' }}>Top</span> </div> },
  { value: '60a588d8fea3cb09902d7793', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneJungle} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>Jungle</span> </div> },
  { value: '60a588d8fea3cb09902d7790', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneMid} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>Mid</span> </div> },
  { value: '60a588d8fea3cb09902d7791', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneBottom} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>ADC</span> </div> },
  { value: '60a588d8fea3cb09902d7792', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneSupport} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>Support</span> </div> },
]

const { TabPane } = Tabs;

enum TabRoutes {
  '/stats/',
  '/stats/champions',
  '/stats/objetives'
}

const FilterInitial = {
  page: 1,
  limit: 10,
  field: 'matchPlayed',
  order: 'descend'
}

const Champions: FC = () => {
  const history = useHistory();
  const { champs } = useGlobalAppState();
  const dispatchApp = useGlobalAppDispatch();
  const [searchFilters, setSearchFilters] = useState<ChampFilters>(FilterInitial);
  const [champStats, setChampStats] = useState<ChampPaginate>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isLtMd } = useWindowSize();

  useEffect(() => {
    const getStatsByLane = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestStats.getChampStats(searchFilters);
        setChampStats(response)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getStatsByLane();
  }, [])

  const goToUrl = (tabNumber: string): void => {
    history.push(TabRoutes[+tabNumber]);
  }

  const getChamps = async(filters: ChampFilters) => {
    setIsLoading(true);
    try {
      const response = await RequestStats.getChampStats(filters);
      setChampStats(response);

      setSearchFilters(filters)
    } finally {
      setIsLoading(false);
    }
  }

  const onSelectChamp = async(champId: string) => {
    getChamps({
      ...searchFilters,
      champId
    })
  }

  const onSelectLane = async(lane: { value: string, label: string }) => {
    getChamps({
      ...searchFilters,
      champId: undefined,
      laneId: lane ? lane.value : undefined
    })
  }

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
    getChamps({
      ...searchFilters,
      page: pagination.current,
      limit: pagination.pageSize,
      field: sorter.field,
      order: sorter.order
    })
  };

  return (
    <>
      <Tabs defaultActiveKey="1" onTabClick={(tabNumber) => goToUrl(tabNumber)}>
        <TabPane tab="Campeones" key="1">
          <CartStyled.Container>
            <div className={`filters d-flex align-items-center ${isLtMd ? 'flex-column' : ''}`}>
              <Select
                showSearch
                allowClear
                style={{ width: isLtMd ? '100%' : 200, marginRight: isLtMd ? 0 : Spacing.Spacing1, marginBottom: Spacing.Spacing1 }}
                placeholder="Buscar campeón"
                optionFilterProp="children"
                onChange={onSelectChamp}
              >
                {champs.length > 0 && champs.map((champ: Champ) => (
                  <Option key={champ._id} value={champ._id}>{champ.name}</Option>
                ))}
              </Select>
              <ChampionStyled.FilterSelect
                placeholder="Buscar por rol"
                className='apg-select-container'
                classNamePrefix="apg-select"
                isClearable
                options={options}
                onChange={(lane: { value: string, label: string }) => onSelectLane(lane)}
              />
            </div>

            <WrapperResponsive>
              <Table
                locale={{ emptyText: 'No hay estadísticas para mostrar' }} 
                pagination={{ 
                  current: champStats?.page,
                  total: champStats?.totalItems
                }}
                rowKey={record => record.key}
                columns={champsColumns()}
                dataSource={champStats?.items}
                onChange={handleTableChange}
                loading={isLoading}
              />
            </WrapperResponsive>
          </CartStyled.Container>
        </TabPane>
        <TabPane tab="Objetivos" key="2"></TabPane>
      </Tabs>
    </>
  )
};

export default Champions;
