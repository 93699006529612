import React, { FC, useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import {
  AimOutlined,
  FlagOutlined,
  HistoryOutlined,
} from '@ant-design/icons';

import IconKills from '@apg-stats/apg-core/lib/IconKills';
import IconHerald from '@apg-stats/apg-core/lib/IconHerald';
import IconBaron from '@apg-stats/apg-core/lib/IconBaron';
import IconTurret from "@apg-stats/apg-core/lib/IconTurret";
import { Palette } from "@apg-stats/apg-core/lib/css-in-js";

import { useSeasonState } from '../../store/season-context';
import { RequestSeasonActions } from '../../store/actions';
import { CartStyled } from '../../../../domain/styles/Card.styled';
import { CustomTitle, WrapperResponsive } from '../../../../domain/styles/ApgStyles.styled';
import { OverviewStatistic } from './Overview.styled';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { SeasonPickBan } from '../../domain/season.interfaces';
import { columnsPick, columnsBan } from '../../domain/season.columns';
import { SeasonStats } from '../../../stats/domain/objetive.interface';
import DrakesTable from '../../../stats/pages/Objetives/Drakes';
import ExtrasTable from '../../../stats/pages/Objetives/Extras';
import TeamSidesTable from '../../../stats/pages/Objetives/TeamSides';

const SeasonOverview: FC = () => {
  const { season } = useSeasonState();
  const dispatchApp = useGlobalAppDispatch();
  const [seasonStats, setSeasonStats] = useState<SeasonStats>();
  const [picks, setPicks] = useState<SeasonPickBan[]>();
  const [bans, setBans] = useState<SeasonPickBan[]>();

  useEffect(() => {
    const getSeason = async (): Promise<void> => {
      try {
        const response = await RequestSeasonActions.getSeasonPicksBans(dispatchApp, season.slug);
        setPicks(response.picks);
        setBans(response.bans);
      } catch (e) {
        // TODO
      }
    }
    
    getSeason();

    const getSeasonStats = async (): Promise<void> => {
      try {
        const response = await RequestSeasonActions.getSeasonStats(dispatchApp, season.slug);
        setSeasonStats(response);
      } catch (e) {
        // TODO
      }
    }
    
    getSeasonStats();
  }, [])
  
  return (
    <>
      {season && seasonStats && (
      <>
        <CartStyled.Container noMinHeight>
          <CustomTitle $boldItalic level={4}>{season.currentSeason}</CustomTitle>

          <Row gutter={[16, 16]}>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Total Partidas"
                value={seasonStats.wins}
                prefix={<FlagOutlined style={{ fontSize: '1.5rem', color: Palette.Gray900 }} />} 
              />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Total kills"
                value={seasonStats.totalKills}
                prefix={<IconKills style={{ fontSize: '1.5rem', color: Palette.Yellow }} />} 
              />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Torretas destruidas"
                value={seasonStats.totalTurrets}
                prefix={<IconTurret style={{ fontSize: '1.5rem' , color: Palette.Gray800}} />} 
              />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Baron Nashor"
                value={`${seasonStats.baronsWins}/${seasonStats.totalBarons}`}
                prefix={<IconBaron style={{ fontSize: '1.5rem' , color: Palette.Baron}} />} 
              />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Heraldo de la Grieta"
                value={`${seasonStats.heraldsWins}/${seasonStats.totalHeralds}`}
                prefix={<IconHerald style={{ fontSize: '1.5rem' , color: Palette.Herald}} />} 
              />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Exterminios"
                value={seasonStats.totalExterminations}
                prefix={<AimOutlined style={{ fontSize: '1.5rem' , color: Palette.SkyBlue}} />} 
              />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Partida mas larga"
                value={seasonStats.duration.max}
                prefix={<HistoryOutlined style={{ fontSize: '1.5rem' , color: Palette.Success}} />} 
              />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6}>
              <OverviewStatistic 
                title="Partida mas corta"
                value={seasonStats.duration.min}
                prefix={<HistoryOutlined style={{ fontSize: '1.5rem' , color: Palette.Danger}} />} 
              />
            </Col>
          </Row>
        </CartStyled.Container>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <CartStyled.Container noMinHeight>
              <CustomTitle $boldItalic level={4}>Top 10 Picks</CustomTitle>
              <WrapperResponsive sm={'200%'}>
                <Table
                  locale={{ emptyText: 'No hay estadísticas para mostrar' }} 
                  pagination={{ hideOnSinglePage: true }}
                  rowKey={record => record._id}
                  columns={columnsPick()}
                  dataSource={picks}
                  size={'small'}
                />
              </WrapperResponsive>
            </CartStyled.Container>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <CartStyled.Container noMinHeight>
              <CustomTitle $boldItalic level={4}>Top 10 Bans</CustomTitle>
              <WrapperResponsive sm={'200%'}>
                <Table
                  locale={{ emptyText: 'No hay estadísticas para mostrar' }} 
                  pagination={{ hideOnSinglePage: true }}
                  rowKey={record => record._id}
                  columns={columnsBan()}
                  dataSource={bans}
                  size={'small'}
                />
              </WrapperResponsive>
            </CartStyled.Container>
          </Col>
        </Row>

        <Row gutter={[{ xs: 16, sm: 16, md: 16, lg: 16 }, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CartStyled.Container>
              <DrakesTable drakes={seasonStats?.drakes} />
            </CartStyled.Container>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <CartStyled.Container style={{ minHeight: '10rem', marginBottom: '1rem' }}>
              <TeamSidesTable teams={seasonStats?.sideWins} />
            </CartStyled.Container>
            <CartStyled.Container style={{ minHeight: '10rem' }}>
              <ExtrasTable data={seasonStats?.miscWinRate} />
            </CartStyled.Container>
          </Col>
        </Row>
      </>
      )}
    </>
  )
};

export default SeasonOverview;