
import { Dispatch } from 'react';
import { AppActions } from '../../../store/reducer';
import { DispatchObject } from '@apg-stats/apg-core/lib/dispatch-object';
import { RequestSeason } from '../proxy/wildrift-season';
import { SeasonActions } from './reducer';
import { SeasonBase } from './reducer';
import { ChampFilters, ChampStat } from '@apg-stats/apg-core/lib/champs';
import { SeasonPicksBans } from '../domain/season.interfaces';
import { SeasonStats } from '../../stats/domain/objetive.interface';

type DispatchApp = Dispatch<DispatchObject<AppActions>>;
type DispatchSeason = Dispatch<DispatchObject<SeasonActions>>;

const getSeason = async (dispatchApp: DispatchApp, dispatchSeason: DispatchSeason, slug: string): Promise<SeasonBase> => {
  dispatchApp({ type: AppActions.IsFetching });
  try {
    const season = await RequestSeason.getSeason(slug);
    dispatchSeason({ type: SeasonActions.SetSeason, payload: season });
    return season;
  } finally {
    dispatchApp({ type: AppActions.FinishedFetching });
  }
};

const getSeasonStats = async (dispatchApp: DispatchApp, slug: string): Promise<SeasonStats> => {
  dispatchApp({ type: AppActions.IsFetching });
  try {
    return await RequestSeason.getSeasonStats(slug);
  } finally {
    dispatchApp({ type: AppActions.FinishedFetching });
  }
};

const getTeams = async (dispatchApp: DispatchApp, dispatchSeason: DispatchSeason, seasonId: string): Promise<void> => {
  dispatchApp({ type: AppActions.IsFetching });
  try {
    const teams = await RequestSeason.getTeams(seasonId);
    dispatchSeason({ type: SeasonActions.SetSeasonTeams, payload: teams });
  } finally {
    dispatchApp({ type: AppActions.FinishedFetching });
  }
};

const getSeasonPickByLane = async (dispatchApp: DispatchApp, seasonId: string): Promise<ChampStat[]> => {
  dispatchApp({ type: AppActions.IsFetching });
  try {
    return await RequestSeason.getSeasonPickByLane(seasonId);
  } finally {
    dispatchApp({ type: AppActions.FinishedFetching });
  }
};

const getSeasonChampStats = async (dispatchApp: DispatchApp, slug: string, searchFilters: ChampFilters): Promise<ChampStat[]> => {
  dispatchApp({ type: AppActions.IsFetching });
  try {
    return await RequestSeason.getSeasonChampStats(slug, searchFilters);
  } finally {
    dispatchApp({ type: AppActions.FinishedFetching });
  }
};

const getSeasonPicksBans = async (dispatchApp: DispatchApp, slug: string): Promise<SeasonPicksBans> => {
  dispatchApp({ type: AppActions.IsFetching });
  try {
    return await RequestSeason.getSeasonPicksBans(slug);
  } finally {
    dispatchApp({ type: AppActions.FinishedFetching });
  }
};

export const RequestSeasonActions = {
  getSeason,
  getSeasonStats,
  getTeams,
  getSeasonPickByLane,
  getSeasonChampStats,
  getSeasonPicksBans
};
