import React, { FC } from 'react';
import { Table } from 'antd';
import { TableStyled } from '../../../../domain/styles/Table.styled';
import { ItemInterface } from '../../domain/objetive.interface';
import { Icons } from '../../domain/icons';

interface DrakeInterface {
  drakes?: ItemInterface[]
}

const DrakesTable: FC<DrakeInterface> = ({ drakes }: DrakeInterface) => {
  const drakeColumns = [
    {
      title: 'Drakes',
      dataIndex: 'drakes',
      key: 'drakes',
      className: 'text-left',
      render: (text: string, record: ItemInterface)  => {
        const drakes = record.icons.map((icon: string) => {
          return Icons(icon, `${record.key}-${icon}-${record.key.length}`) 
        })

        return drakes
      },
    },
    {
      title: 'Win rate',
      dataIndex: 'winRate',
      key: 'winRate',
      className: 'text-center',
      width: 100,
      render: (text: string, record: ItemInterface)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <TableStyled.Text>{record.winRate}</TableStyled.Text>
          </div>
        )
      },
    },
    {
      title: 'Realizado',
      dataIndex: 'objetive',
      key: 'objetive',
      className: 'text-center',
      width: 100,
      render: (text: string, record: ItemInterface)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <TableStyled.Text>{record.total}</TableStyled.Text>
          </div>
        )
      },
    }
  ];

  return (
    <Table
      locale={{ emptyText: 'No hay estadísticas de los dragones para mostrar' }}
      rowKey={record => `${record.key}-${record.total}-${record.wins}`}
      columns={drakeColumns}
      pagination={false}
      dataSource={drakes}
      size={'small'}
    />
  )
};

export default DrakesTable;
