import { ReactElement } from "react";
import IconLaneBottom from "@apg-stats/apg-core/lib/IconLaneBottom";
import IconLaneJungle from "@apg-stats/apg-core/lib/IconLaneJungle";
import IconLaneMid from "@apg-stats/apg-core/lib/IconLaneMid";
import IconLaneSupport from "@apg-stats/apg-core/lib/IconLaneSupport";
import IconLaneTop from "@apg-stats/apg-core/lib/IconLaneTop";
import { Palette } from "@apg-stats/apg-core/lib/css-in-js";
import { capitalize } from "../utils/helpers";

export const LaneIcons = (lane: string): ReactElement<string, string> => {
  switch (capitalize(lane)) {
    case 'Baron': return <IconLaneTop style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 'Jungle': return <IconLaneJungle style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 'Mid': return <IconLaneMid style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 'Dragon': return <IconLaneBottom style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 'Support': return <IconLaneSupport style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    default: return <div></div>;
  }
}

export const LaneIconsByIndex = (lane: number): ReactElement<string, string> => {
  switch (lane) {
    case 0: return <IconLaneTop style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 1: return <IconLaneJungle style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 2: return <IconLaneMid style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 3: return <IconLaneBottom style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    case 4: return <IconLaneSupport style={{ fontSize: '1.5rem' , color: Palette.Gray700}}/>;
    default: return <div></div>;
  }
}