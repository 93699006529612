import React, { FC, useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { AppActions } from '../../../../store/reducer';
import { RequestWildRift } from '../../../../proxy/wildrift';
import { ProBuild } from '@apg-stats/apg-core/lib/probuilds';
import ApgProBuildCard from '@apg-stats/apg-core/lib/ApgProBuildCard';
import { useCookies } from 'react-cookie';
import { useHistory, useRouteMatch } from 'react-router-dom';

const ProBuilds: FC = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const [cookies] = useCookies();
  const dispatchApp = useGlobalAppDispatch();
  const [proBuilds, setProBuilds] = useState<ProBuild[]>()

  useEffect(() => {
    const getStatsByLane = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestWildRift.getRandomProBuilds(cookies.APGTOKEN);
        setProBuilds(response)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getStatsByLane();
  }, [])

  const goToUrl = (path: string): void => {
    history.push(path);
  }

  return (
    <>
      <Row gutter={[{ xs: 16, sm: 16, md: 16, lg: 16 }, 16]}>
        {proBuilds && proBuilds.map((proBuild: ProBuild) => (
          <Col xs={12} sm={12} md={12} lg={6} xl={6} key={proBuild._id}>
            <ApgProBuildCard 
              build={proBuild}
              onClick={(build) => goToUrl( url !== '/' ? `../probuilds/${build._id}` : `probuilds/${build._id}`)}
            />
          </Col>
        ))}
      </Row>
    </>
  )
};

export default ProBuilds;
