import React, { FC, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Layout, Menu } from 'antd';
import { useGlobalAppDispatch } from '../../../../store/app-context';

import Section from '@apg-stats/apg-core/lib/ApgSection';
import { Palette } from '@apg-stats/apg-core/lib/css-in-js';
import { Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { TournamentStyled } from './Tournament.styled';

import {
  BarChartOutlined,
  HomeOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { useTournamentDispatch } from '../../store/tournament-context';
import { RequestTournamentActions } from '../../store/actions';
import { TournamentBase } from '../../store/reducer';
import { AppActions } from '../../../../store/reducer';

const { Content } = Layout;

interface ParamTypes {
  slug: string
}

const Tournament: FC = () => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatchApp = useGlobalAppDispatch();
  const dispatchTournament = useTournamentDispatch();
  const [tournament, setTournament] = useState<TournamentBase>();
  const [title, setTitle] = useState<string>("Cargando torneo...");
  const { slug } = useParams<ParamTypes>();

  useEffect(() => {
    const getTournament = async (): Promise<void> => {
      try {
        const tournament = await RequestTournamentActions.getTournament(dispatchApp, dispatchTournament, slug);
        setTournament(tournament);
        setTitle(tournament.name);
      } catch (e) {
        // TODO
      }
    }
    
    getTournament();
  }, [])
  
  const isLtLg = useMedia('(max-width: 1200px)');

  const goToUrl = (uri: string): void => {
    history.push(uri);
  }

  useEffect(() => {
    if (tournament) {
      dispatchApp({ 
        type: AppActions.SetSecondaryMenuItems,
        payload: {
          showSecondaryMenu: true,
          secondaryMenuItems: [
            {
              id: 'home',
              title: 'Home',
              href: `${url}`
            },
            {
              id: 'stats',
              title: 'Estadísticas globales',
              href: `${url}/stats`
            },
            {
              id: 'seasons',
              title: 'Temporadas',
              href: `${url}/seasons`
            }
          ]
        }
      })
    }
  }, [tournament])
  
  return (
    <>
    {tournament && (
      <Layout style={{ backgroundColor: Palette.Gray100, margin: '4rem 0 0' }} className="flex-row">
        <TournamentStyled.ApgSider 
          breakpoint="xl"
          collapsedWidth="60"
          width={'16.875rem'}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%', borderRight: 0 }}
          >
            <div className="d-flex flex-column mb-4 mt-4 align-items-center">
              <img src={tournament?.logoUrl } style={{ width: isLtLg ? '3rem' : '8rem', borderRadius: '50%' }} />
              {!isLtLg && <h5 className="mt-4">{tournament?.name}</h5>}
            </div>
            <Menu.Item key="1" icon={<HomeOutlined />} onClick={() => goToUrl(`${url}`)}>Home</Menu.Item>
            <Menu.Item key="2" icon={<BarChartOutlined />} onClick={() => goToUrl(`${url}/stats`)}>Estadísticas globales</Menu.Item>
            <Menu.Item key="3" icon={<ThunderboltOutlined />} onClick={() => goToUrl(`${url}/seasons`)}>Temporadas</Menu.Item>
          </Menu>
        </TournamentStyled.ApgSider>
        <Layout style={{ backgroundColor: Palette.Gray100, padding: '0 24px 24px', marginLeft: isLtLg ? '3.75rem' : '16.875rem' }}>
          <Section spacing={'sm'}>
            <Content className="container">
              <Switch>
                <Route exact path={path}>
                  <div>Home</div>
                </Route>
                <Route path={`${path}/stats`}>
                  <div>Estadísticas globales</div>
                </Route>
                <Route path={`${path}/seasons`}>
                  <div>Temporadas</div>
                </Route>
                <Redirect from="*" to={path} />
              </Switch>
            </Content>
          </Section>
        </Layout>
      </Layout>
    )}
    </>
  )
};

export default Tournament;
