import React, { FC, useEffect, useState } from 'react';
import { Select, Table, TablePaginationConfig } from 'antd';
import useWindowSize from '@apg-stats/apg-core/lib/useWindowSize';
import ApgIcon from '@apg-stats/apg-core/lib/ApgIcon';
import { Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import IconLaneBottom from '@apg-stats/apg-core/lib/IconLaneBottom';
import IconLaneJungle from '@apg-stats/apg-core/lib/IconLaneJungle';
import IconLaneMid from '@apg-stats/apg-core/lib/IconLaneMid';
import IconLaneSupport from '@apg-stats/apg-core/lib/IconLaneSupport';
import IconLaneTop from '@apg-stats/apg-core/lib/IconLaneTop';
import { useSeasonState } from '../../../store/season-context';
import { useGlobalAppDispatch, useGlobalAppState } from '../../../../../store/app-context';
import { Champ, ChampFilters, ChampStat } from '@apg-stats/apg-core/lib/champs';
import { AppActions } from '../../../../../store/reducer';
import { RequestSeason } from '../../../proxy/wildrift-season';
import { ChampionStyled } from '../../../../stats/pages/Champions/Champions.styled';
import { WrapperResponsive } from '../../../../../domain/styles/ApgStyles.styled';
import { champsColumns } from '../../../../stats/domain/champion-columns';
import { FilterValue } from 'antd/lib/table/interface';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const options = [
  { value: '60a588d8fea3cb09902d778f', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneTop} alt="Solo lane" /> <span style={{ marginLeft: '.5rem' }}>Top</span> </div> },
  { value: '60a588d8fea3cb09902d7793', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneJungle} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>Jungle</span> </div> },
  { value: '60a588d8fea3cb09902d7790', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneMid} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>Mid</span> </div> },
  { value: '60a588d8fea3cb09902d7791', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneBottom} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>ADC</span> </div> },
  { value: '60a588d8fea3cb09902d7792', label: <div className="d-flex align-items-center"><ApgIcon size={16} icon={IconLaneSupport} alt="Mid lane" /> <span style={{ marginLeft: '.5rem' }}>Support</span> </div> },
]

const FilterInitial = {
  page: 1,
  limit: 200,
  top: true,
  field: 'matchPlayed',
  order: 'descend'
}

const SeasonStatsChampions: FC = () => {
  const { season } = useSeasonState();
  const { champs } = useGlobalAppState();
  const history = useHistory();
  const dispatchApp = useGlobalAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<ChampFilters>(FilterInitial);
  const [champStats, setChampStats] = useState<ChampStat[]>();
  const { isLtMd } = useWindowSize();
  
  useEffect(() => {
    const getChamps = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestSeason.getSeasonChampStats(season.slug, searchFilters);
        setChampStats(response)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getChamps();
  }, [])

  const getChamps = async(filters: ChampFilters) => {
    setIsLoading(true);
    try {
      const response = await RequestSeason.getSeasonChampStats(season.slug, filters);
      setChampStats(response)

      setSearchFilters(filters)
    } finally {
      setIsLoading(false);
    }
  }

  const onSelectChamp = async(champId: string) => {
    getChamps({
      ...searchFilters,
      champId
    })
  }

  const onSelectLane = async(lane: { value: string, label: string }) => {
    getChamps({
      ...searchFilters,
      champId: undefined,
      laneId: lane ? lane.value : undefined
    })
  }

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
    getChamps({
      ...searchFilters,
      page: pagination.current,
      limit: pagination.pageSize,
      field: sorter.field,
      order: sorter.order
    })
  };

  const goToChampDetail = (championId: string, role: string): void => {
    history.push(`/seasons/${season.slug}/champions/${championId}?role=${role}`);
  }
  
  return (
    <>
      <div className={`filters d-flex align-items-center ${isLtMd ? 'flex-column' : ''}`}>
        <Select
          showSearch
          allowClear
          style={{ width: isLtMd ? '100%' : 200, marginRight: isLtMd ? 0 : Spacing.Spacing1, marginBottom: Spacing.Spacing1 }}
          placeholder="Buscar campeón"
          optionFilterProp="children"
          onChange={onSelectChamp}
        >
          {champs.length > 0 && champs.map((champ: Champ) => (
            <Option key={champ._id} value={champ._id}>{champ.name}</Option>
          ))}
        </Select>
        <ChampionStyled.FilterSelect
          placeholder="Buscar por rol"
          className='apg-select-container'
          classNamePrefix="apg-select"
          isClearable
          options={options}
          onChange={(lane: { value: string, label: string }) => onSelectLane(lane)}
        />
      </div>

      <WrapperResponsive>
        <Table
          locale={{ emptyText: 'No hay estadísticas para mostrar' }} 
          pagination={false}
          rowKey={record => record.key}
          columns={champsColumns()}
          dataSource={champStats}
          onChange={handleTableChange}
          loading={isLoading}
          bordered={true}
          size={'small'}
          onRow={(record) => {
            return {
              onClick: () => goToChampDetail(record.slug, record.role)
            };
          }}
        />
      </WrapperResponsive>
    </>
  )
};

export default SeasonStatsChampions;