import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Result, Tabs, Tooltip, Typography } from 'antd';
import { useGlobalAppDispatch } from "../../../../store/app-context";
import { AppActions } from "../../../../store/reducer";
import { RequestSeason } from "../../proxy/wildrift-season";
import { SeasonMatchBan, SeasonMatchDetail, SeasonMatchSet } from "../../domain/matches";
import { useSeasonState } from "../../store/season-context";
import { CartStyled } from "../../../../domain/styles/Card.styled";
import { MatchTeams, MatchTeam, MatchScores, MatchScore, MatchVersus, CustomTabs, MatchSetRow, ChampAvatar } from "./Match.styled";
import IconKills from '@apg-stats/apg-core/lib/IconKills';
import IconDrake from '@apg-stats/apg-core/lib/IconDrake';
import IconHerald from '@apg-stats/apg-core/lib/IconHerald';
import IconBaron from '@apg-stats/apg-core/lib/IconBaron';
import IconTurret from "@apg-stats/apg-core/lib/IconTurret";
import { Palette } from "@apg-stats/apg-core/lib/css-in-js";
import MatchSetCols, { MatchSetColsInterface } from "../../components/MatchSetCols";
import { Icons } from "../../../stats/domain/icons";
import MatchPick from "../../components/MatchPick";
import useWindowSize from "@apg-stats/apg-core/lib/useWindowSize";
import { LaneIconsByIndex } from "../../../../domain/lane-icons";

const { Text } = Typography;
const { TabPane } = Tabs;

interface ParamTypes {
  matchId: string
}

const SeasonMatch: FC = () => {
  const history = useHistory();
  const { matchId } = useParams<ParamTypes>();
  const { season } = useSeasonState();
  const dispatchApp = useGlobalAppDispatch();
  const [match, setMatch] = useState<SeasonMatchDetail>();
  const { isXs } = useWindowSize()

  useEffect(() => {
    const getSeasonMatches = async (): Promise<void> => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const match = await RequestSeason.getSeasonMatch(season.seasonID, matchId);
        setMatch(match);
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    }
    
    getSeasonMatches();
  }, [])

  const goToUrl = (): void => {
    history.push(`/${season.uri}/matches`);
  }

  return (
    <>
    {match && (
      <>
        <CartStyled.Container noMinHeight>
          <MatchTeams>
            <MatchTeam type="home" winner={match.home.winner} isComplete={match.isComplete}>
              <img src={match.home.logoUrl} alt={match.home.name} />
              <span className="team-name">{match.home.name}</span>
            </MatchTeam>
            <div className="divider d-flex align-items-center">
              {match.isComplete && (
                <MatchScores>
                  <MatchScore type="home" winner={match.home.winner}>{match.home.score}</MatchScore>
                  <div className="separator">-</div>
                  <MatchScore type="away" winner={match.away.winner}>{match.away.score}</MatchScore>
                </MatchScores>
              )}
              {!match.isComplete && (
                <MatchVersus>vs</MatchVersus>
              )}
            </div>
            <MatchTeam type="away" winner={match.away.winner} isComplete={match.isComplete}>
              <img src={match.away.logoUrl} alt={match.away.name} />
              <span className="team-name">{match.away.name}</span>
            </MatchTeam>
          </MatchTeams>
        </CartStyled.Container>

        {match.sets.length === 0 && !match.isComplete && (
          <CartStyled.Container noMinHeight>
            <Result
              title="Este partido aún no empieza o está en jugandosé ahora mismo, esperen los resultados."
              extra={
                <Button type="primary" key="console" onClick={() => goToUrl()}>
                  Volver a los partidos
                </Button>
              }
            />
          </CartStyled.Container>
        )}

        {match.sets.length > 0 && (
          <CustomTabs defaultActiveKey="1">
            {match.sets && match.sets.map((set: SeasonMatchSet) => {
              const stats = set.stats.length === 4 ? set.stats.slice(-2) : set.stats;

              const topDrakes = stats[0].drakes.map((icon: string) => {
                return (<div key={icon}>{ Icons(icon, icon) }</div>)
              })

              const bottomDrakes = stats[1].drakes.map((icon: string) => {
                return (<div key={icon}>{ Icons(icon, icon) }</div>)
              })

              const topBans = stats[0].bans.map((ban: SeasonMatchBan) => {
                return (
                  <ChampAvatar key={ban._id} src={ban.champ.avatarUrl} alt={ban.champ.name} right />
                )
              })

              const bottomBans = stats[1].bans.map((ban: SeasonMatchBan) => {
                return (
                  <ChampAvatar key={ban._id} src={ban.champ.avatarUrl} alt={ban.champ.name} left />
                )
              })

              const merged: MatchSetColsInterface[]  = [];

              for(let i=0; i< stats[0].picks.length; i++) {
                const pickTop = stats[0].picks[i];
                const pickBottom = stats[1].picks[i];
                const icon = LaneIconsByIndex(i);

                const top = <MatchPick pick={pickTop} side={'home'} />;
                const bottom = <MatchPick pick={pickBottom} side={'away'} />;

                merged.push({
                  top,
                  bottom,
                  icon
                });

              }

              return (
                <TabPane tab={`Partida ${set.setOrder}`} key={set.setOrder}>
                  {merged.length === 0 && match.isComplete && (
                    <CartStyled.Container noMinHeight>
                      <Result
                        status="warning"
                        title="No tenemos información de estas partidas, solo el resultado final del partido."
                        extra={
                          <Button type="primary" key="console" onClick={() => goToUrl()}>
                            Volver a los partidos
                          </Button>
                        }
                      />
                    </CartStyled.Container>
                  )}
                  {merged.length > 0 && (
                    <>
                      <CartStyled.Container noMinHeight>
                        <MatchSetRow>
                          <MatchSetCols 
                            top={stats[0].kills} 
                            bottom={stats[1].kills}
                            icon={
                              <Tooltip title="Kills">
                                <IconKills style={{ fontSize: '1.5rem', color: Palette.Gray700 }} />
                              </Tooltip>
                            }
                          />
                        </MatchSetRow>
                        <MatchSetRow>
                          <MatchSetCols 
                            top={stats[0].turrets} 
                            bottom={stats[1].turrets}
                            icon={
                              <Tooltip title="Torretas destruidas">
                                <IconTurret style={{ fontSize: '1.5rem' , color: Palette.Gray700}} />
                              </Tooltip>
                            }
                          />
                        </MatchSetRow>
                        <MatchSetRow>
                          <MatchSetCols 
                            top={topDrakes} 
                            bottom={bottomDrakes}
                            icon={
                              <Tooltip title="Drakes obtenidos">
                                <IconDrake style={{ fontSize: '1.5rem', color: Palette.Gray700 }} />
                              </Tooltip>
                            }
                          />
                        </MatchSetRow>
                        <MatchSetRow>
                          <MatchSetCols 
                            top={stats[0].herald ? 1 : 0} 
                            bottom={stats[1].herald ? 1 : 0}
                            icon={
                              <Tooltip title="Heraldo de la grieta">
                                <IconHerald style={{ fontSize: '1.5rem', color: Palette.Gray700 }} />
                              </Tooltip>
                            }
                          />
                        </MatchSetRow>
                        <MatchSetRow>
                          <MatchSetCols 
                            top={stats[0].baron} 
                            bottom={stats[1].baron}
                            icon={
                              <Tooltip title="Baron Nashor">
                                <IconBaron style={{ fontSize: '1.5rem', color: Palette.Gray700 }} />
                              </Tooltip>
                            }
                          />
                        </MatchSetRow>
                        <MatchSetRow last>
                          <MatchSetCols 
                            top={topBans} 
                            bottom={bottomBans}
                            icon={
                              <Tooltip title="Campeones baneados">
                                <Text strong>BANS</Text>
                              </Tooltip>
                            }
                          />
                        </MatchSetRow>
                      </CartStyled.Container>
                    
                      <CartStyled.Container noMinHeight>
                        <div className="d-flex flex-column">
                          {merged && merged.map((row: MatchSetColsInterface, index: number) => (
                            <MatchSetRow height={ isXs ? '15rem' : '9rem' } key={index}>
                              <MatchSetCols 
                                top={row.top}
                                bottom={row.bottom}
                                icon={row.icon}
                              />
                            </MatchSetRow>
                          ))}
                        </div>
                      </CartStyled.Container>
                    </>
                  )}
                </TabPane>
              )
            })}
          </CustomTabs>
        )}
      </>
    )}
    </>
  )
}

export default SeasonMatch;