import { ReactElement } from "react";
import ApgIcon from "@apg-stats/apg-core/lib/ApgIcon";
import IconDrakeCloud from "@apg-stats/apg-core/lib/IconDrakeCloud";
import IconDrakeInfernal from "@apg-stats/apg-core/lib/IconDrakeInfernal";
import IconDrakeMountain from "@apg-stats/apg-core/lib/IconDrakeMountain";
import IconDrakeOcean from "@apg-stats/apg-core/lib/IconDrakeOcean";
import IconTurret from '@apg-stats/apg-core/lib/IconTurret';
import IconTurretBlue from "@apg-stats/apg-core/lib/IconTurretBlue";
import IconTurretRed from '@apg-stats/apg-core/lib/IconTurretRed';
import IconFirstBlood from '@apg-stats/apg-core/lib/IconFirstBlood';
import IconBaron from "@apg-stats/apg-core/lib/IconBaron";
import IconHerald from "@apg-stats/apg-core/lib/IconHerald";

export const Icons = (icon: string, key: string): ReactElement<string, string> => {
  switch (icon) {
    case 'infernal':
    case 'infernal-drake': 
      return <ApgIcon key={key} size={24} icon={IconDrakeInfernal} alt="Infernal" />;
    case 'ocean': 
    case 'ocean-drake': 
      return <ApgIcon key={key} size={24} icon={IconDrakeOcean} alt="Ocean" />;
    case 'wind': 
    case 'wind-drake': 
      return <ApgIcon key={key} size={24} icon={IconDrakeCloud} alt="Cloud" />;
    case 'mountain':
    case 'mountain-drake':
      return <ApgIcon key={key} size={24} icon={IconDrakeMountain} alt="Mountain" />;
    case 'turret': return <ApgIcon key={key} size={24} icon={IconTurret} alt="Torreta" />;
    case 'turretBlue': return <ApgIcon key={key} size={24} icon={IconTurretBlue} alt="Torreta" />;
    case 'turretRed': return <ApgIcon key={key} size={24} icon={IconTurretRed} alt="Torreta" />;
    case 'firstBlood': return <ApgIcon key={key} size={24} icon={IconFirstBlood} alt="Primera sangre" />;
    case 'herald': return <ApgIcon key={key} size={24} icon={IconHerald} alt="Heraldo" />;
    case 'baron': return <ApgIcon key={key} size={24} icon={IconBaron} alt="Baron Nashor" />;
    default: return <div></div>;
  }
}