import React, { FC, useEffect, useState } from 'react';
import { Select, Table, TablePaginationConfig } from 'antd';
import useWindowSize from '@apg-stats/apg-core/lib/useWindowSize';
import { Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import { useSeasonState } from '../../../store/season-context';
import { useGlobalAppDispatch, useGlobalAppState } from '../../../../../store/app-context';
import { AppActions } from '../../../../../store/reducer';
import { WrapperResponsive } from '../../../../../domain/styles/ApgStyles.styled';
import { PlayerFilters, PlayerStat } from '../../../../../domain/player.interface';
import { Champ } from '@apg-stats/apg-core/lib/champs';
import { columnsSeasonPlayer } from '../../../domain/player.columns';
import { SeasonTeam } from '../../../store/reducer';
import { FilterValue } from 'antd/lib/table/interface';
import _ from 'lodash';
import { RequestSeasonPlayer } from '../../../proxy/wildrift-season-players';
import { useHistory } from 'react-router-dom';

const { Option } = Select;
const FilterInitial = {
  page: 1,
  limit: 500,
  field: 'kda',
  order: 'descend'
}

const SeasonStatsPlayers: FC = () => {
  const history = useHistory();
  const { season, teams: seasonTeams } = useSeasonState();
  const { champs } = useGlobalAppState();
  const dispatchApp = useGlobalAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchFilters, setSearchFilters] = useState<PlayerFilters>(FilterInitial);
  const [players, setPlayers] = useState<PlayerStat[]>();
  const { isLtMd } = useWindowSize();
  
  useEffect(() => {
    const getPlayersStats = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestSeasonPlayer.getPlayersStats(season.slug, searchFilters);
        setPlayers(response.items)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getPlayersStats();
  }, [])

  const getPlayers = async(filters: PlayerFilters) => {
    setIsLoading(true);
    try {
      const response = await RequestSeasonPlayer.getPlayersStats(season.slug, filters);
      setPlayers(response.items)

      setSearchFilters(filters)
    } finally {
      setIsLoading(false);
    }
  }

  const onSelectChamp = async(champId: string) => {
    getPlayers({
      ...searchFilters,
      teamId: null,
      champId
    })
  }

  const onSelectTeam = async(teamId: string) => {
    getPlayers({
      ...searchFilters,
      champId: null,
      teamId
    })
  }

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any) => {
    const field = sorter.field;
    const order = sorter.order === 'ascend' ? 'asc' : 'desc';

    const sortedPlayers = _.orderBy(players, [field], [order]) as PlayerStat[];
    setPlayers(sortedPlayers)
  };

  const goToPlayer = (playerSlug: string): void => {
    history.push(`/seasons/${season.slug}/players/${playerSlug}`);
  }
  
  return (
    <>
      <div className={`filters d-flex align-items-center ${isLtMd ? 'flex-column' : ''}`}>
        <Select
          showSearch
          allowClear
          style={{ width: isLtMd ? '100%' : 200, marginRight: isLtMd ? 0 : Spacing.Spacing1, marginBottom: Spacing.Spacing1 }}
          placeholder="Buscar campeón"
          optionFilterProp="children"
          onChange={onSelectChamp}
        >
          {champs.length > 0 && champs.map((champ: Champ) => (
            <Option key={champ._id} value={champ._id}>{champ.name}</Option>
          ))}
        </Select>
        <Select
          showSearch
          allowClear
          style={{ width: isLtMd ? '100%' : 200, marginRight: isLtMd ? 0 : Spacing.Spacing1, marginBottom: Spacing.Spacing1 }}
          placeholder="Buscar equipo"
          optionFilterProp="children"
          onChange={onSelectTeam}
        >
          {seasonTeams.length > 0 && seasonTeams.map((team: SeasonTeam) => (
            <Option key={team._id} value={team._id}>{team.name}</Option>
          ))}
        </Select>
      </div>

      <WrapperResponsive sm={'215%'}>
        <Table
          locale={{ emptyText: 'No hay jugadores para mostrar' }} 
          pagination={false}
          rowKey={record => record._id}
          columns={columnsSeasonPlayer()}
          dataSource={players}
          onChange={handleTableChange}
          loading={isLoading}
          bordered={true}
          size={'small'}
          onRow={(record) => {
            return {
              onClick: () => goToPlayer(record.slug)
            };
          }}
        />
      </WrapperResponsive>
    </>
  )
};

export default SeasonStatsPlayers;