import styled from "styled-components";
import { MediaQuery, Palette, Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import { Font12, Font16, Font24, Font38, Font54, FontBoldItalic } from "./ApgTypography.styled";

const Page = styled.div`
  background-size: cover;
  background-position: center center;
  border-bottom: 2px solid ${Palette.DarkBlue600};
  display: flex;
  justify-content: center;
  margin: 4rem 0 0;
  position: relative;

  &:before {
    background-color: ${Palette.Opacity50};
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

const Profile = styled.div`
  background-size: cover;
  background-position: center center;
  border-bottom: 2px solid ${Palette.DarkBlue600};
  display: flex;
  justify-content: flex-start;
  margin: 4rem 0 0;
  position: relative;

  &:before {
    background-color: ${Palette.Opacity50};
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .title {
    ${MediaQuery.LessThanMedium} {
      ${Font24}
    }
  }
`

const Build = styled.div`
  background-size: cover;
  background-position: top center;
  border-bottom: 2px solid ${Palette.DarkBlue600};
  display: flex;
  justify-content: flex-start;
  margin: 4rem 0 0;
  position: relative;

  &:before {
    background-color: ${Palette.Opacity50};
    content: "";
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .container {
    padding-bottom: ${Spacing.Spacing6};
    padding-top: ${Spacing.Spacing6};
  }

  .title {
    ${MediaQuery.LessThanMedium} {
      ${Font24}
    }

    span {
      display: block;
      ${Font16}
      ${MediaQuery.LessThanMedium} {
        ${Font12}
      }
    }
  }
`

const Logo = styled.div`
  background-color: ${Palette.Gray100};
  border: 2px solid ${Palette.DarkBlue600};
  border-radius: 50%;
  height: auto;
  margin-right: ${Spacing.Spacing2};
  overflow: hidden;
  width: 8rem;

  ${MediaQuery.LessThanMedium} {
    width: 5rem;
  }

  img {
    width: 100%;
  }
`

const Title = styled.div`
  color: ${Palette.Gray100};
  ${FontBoldItalic}
  ${Font54}
  padding: ${Spacing.Spacing7} 0;
  z-index: 2;

  ${MediaQuery.LessThanMedium} {
    ${Font38}
  }
`

export const HeroBannerStyled = {
  Page,
  Profile,
  Build,
  Title,
  Logo
}