import { Palette } from "@apg-stats/apg-core/lib/css-in-js"
import { Tabs } from "antd"
import styled from "styled-components"

export const StatsTabs = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      display: none;
    }
  }

  .ant-tabs-nav-wrap {
    background-color: ${Palette.Gray200};
    border-radius: 0.625rem;
    padding: .5rem;
    width: 100%;
  }

  .ant-tabs-nav-list {
    flex: auto;

    .ant-tabs-tab {
      flex: 1 1 0px;
      justify-content: center;
      margin: 0.25rem;
      padding: .5rem 0;
  
      & + .ant-tabs-tab {
        margin: 0 !important;
      }
    }
  }
`