import React, { FC, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Col, Row, Table, Tabs } from 'antd';
import { useGlobalAppDispatch } from "../../../../store/app-context";
import { AppActions } from "../../../../store/reducer";
import { RequestSeason } from "../../proxy/wildrift-season";
import { useSeasonState } from "../../store/season-context";
import { CartStyled } from "../../../../domain/styles/Card.styled";
import IconKills from '@apg-stats/apg-core/lib/IconKills';
import { Palette } from "@apg-stats/apg-core/lib/css-in-js";
import { ChampionStatDetail, ChampMatchups, ChampByPlayer } from '../../../../domain/champion.interface';
import { FlagOutlined, AimOutlined, CheckCircleOutlined, CloseCircleOutlined, FireOutlined } from "@ant-design/icons";
import { CustomTitle, WrapperResponsive } from "../../../../domain/styles/ApgStyles.styled";
import { OverviewStatistic } from "../Overview/Overview.styled";
import IconTurret from '@apg-stats/apg-core/lib/IconTurret';
import { ChampRoleImage, ChampThumb } from "./Champion.styled";
import { columnsChampMatchupWith, columnsChampMatchupAgainst, columnsChampMatches, columnsChampPlayers } from '../../domain/season.columns';
import { StatsTabs } from "../Stats/Stats.styled";

const { TabPane } = Tabs;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

interface ParamTypes {
  championSlug: string
}

const SeasonChampion: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const { championSlug } = useParams<ParamTypes>();
  const { season } = useSeasonState();
  const dispatchApp = useGlobalAppDispatch();
  const [champion, setChampion] = useState<ChampionStatDetail>();
  const [matchups, setMatchups] = useState<ChampMatchups>();
  const [players, setPlayers] = useState<ChampByPlayer[]>();
  const [isLoadingMatchups, setIsLoadingMatchups] = useState<boolean>(false);
  const [isLoadingPlayers, setIsLoadingPlayers] = useState<boolean>(false);

  const getSeasonChampions = async (role: string): Promise<void> => {
    dispatchApp({ type: AppActions.IsFetching });
    try {
      const champion = await RequestSeason.getSeasonChampionDetail(season.slug, championSlug, role || '');
      setChampion(champion);
    } finally {
      dispatchApp({ type: AppActions.FinishedFetching });
    }
  }

  const getSeasonChampionsMatchups = async (role: string): Promise<void> => {
    setIsLoadingMatchups(true);
    try {
      const matchups = await RequestSeason.getSeasonChampionMatchups(season.slug, championSlug, role || '');
      setMatchups(matchups);
    } finally {
      setIsLoadingMatchups(false);
    }
  }

  const getSeasonChampionsPlayers = async (role: string): Promise<void> => {
    setIsLoadingPlayers(true);
    try {
      const players = await RequestSeason.getSeasonChampionPlayers(season.slug, championSlug, role || '');
      setPlayers(players);
    } finally {
      setIsLoadingPlayers(false);
    }
  }

  useEffect(() => {
    const role = query.get("role");

    if (role) {
      getSeasonChampions(role);
      getSeasonChampionsMatchups(role);
      getSeasonChampionsPlayers(role);
    }
  }, [championSlug]);

  const goToMatch = (matchId: string): void => {
    history.push(`/seasons/${season.slug}/matches/${matchId}`);
  }

  const goToChamp = (championSlug: string, role: string): void => {
    history.push(`/seasons/${season.slug}/champions/${championSlug}?role=${role}`);
  }

  const goToPlayer = (playerSlug: string): void => {
    history.push(`/seasons/${season.slug}/players/${playerSlug}`);
  }

  return (
    <>
    {champion && (
      <>
        <CartStyled.Container noMinHeight>
          <CustomTitle $boldItalic level={4}>{champion.name}</CustomTitle>

          <div className="d-flex align-items-start justify-content-start gap-3 flex-column flex-md-row">

            <div className="champ-avatar position-relative">
              <ChampThumb src={champion.images.avatarUrl} alt={champion.name} />
              <ChampRoleImage src={champion.lane.image} alt={champion.lane.name} />
            </div>

            <Row gutter={[16, 16]} className="flex-1">
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Partidas Ganadas"
                  value={champion.wins}
                  prefix={<FlagOutlined style={{ fontSize: '1.5rem', color: Palette.Gray900 }} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="K/D/A"
                  value={`${champion.kills}/${champion.deaths}/${champion.assistants}`}
                  prefix={<IconKills style={{ fontSize: '1.5rem', color: Palette.Yellow }} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="# Picks"
                  value={champion.picks}
                  prefix={<CheckCircleOutlined style={{ fontSize: '1.5rem' , color: Palette.SkyBlue}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="# Bans"
                  value={champion.bans}
                  prefix={<CloseCircleOutlined style={{ fontSize: '1.5rem' , color: Palette.Danger}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="KDA"
                  value={`${champion.kda.toFixed(2)}`}
                  prefix={<AimOutlined style={{ fontSize: '1.5rem' , color: Palette.Gray800}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="WinRate"
                  value={`${champion.winRate.toFixed(2)}%`}
                  prefix={<FireOutlined style={{ fontSize: '1.5rem' , color: Palette.Success}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Lado Azul"
                  value={`${champion.blueWins}/${champion.blueMatches}`}
                  prefix={<IconTurret style={{ fontSize: '1.5rem' , color: Palette.SkyBlue}} />}
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Lado Rojo"
                  value={`${champion.redWins}/${champion.redMatches}`}
                  prefix={<IconTurret style={{ fontSize: '1.5rem' , color: Palette.Danger}} />}
                />
              </Col>
            </Row>
          </div>
        </CartStyled.Container>

        <StatsTabs defaultActiveKey="1">
          <TabPane tab="Matchups" key="1">
            <CartStyled.Container noMinHeight>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <CartStyled.Container noMinHeight>
                    <CustomTitle $boldItalic level={4}>Jugó con</CustomTitle>
                    {champion.lane.slug !== 'baron' && (
                      <WrapperResponsive sm={'200%'}>
                        <Table
                          locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                          pagination={false}
                          rowKey={record => `${record.role}-${record.slug}`}
                          columns={columnsChampMatchupWith('Baron')}
                          dataSource={matchups?.with.baron}
                          loading={isLoadingMatchups}
                          size={'small'}
                          onRow={(record) => {
                            return {
                              onClick: () => goToChamp(record.slug, record.role)
                            };
                          }}
                        />
                      </WrapperResponsive>
                    )}

                    {champion.lane.slug !== 'jungle' && (
                      <WrapperResponsive sm={'200%'}>
                        <Table
                          locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                          pagination={false}
                          rowKey={record => `${record.role}-${record.slug}`}
                          columns={columnsChampMatchupWith('Jungla')}
                          dataSource={matchups?.with.jungle}
                          loading={isLoadingMatchups}
                          size={'small'}
                          onRow={(record) => {
                            return {
                              onClick: () => goToChamp(record.slug, record.role)
                            };
                          }}
                        />
                      </WrapperResponsive>
                    )}

                    {champion.lane.slug !== 'mid' && (
                      <WrapperResponsive sm={'200%'}>
                        <Table
                          locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                          pagination={false}
                          rowKey={record => `${record.role}-${record.slug}`}
                          columns={columnsChampMatchupWith('Mid')}
                          dataSource={matchups?.with.mid}
                          loading={isLoadingMatchups}
                          size={'small'}
                          onRow={(record) => {
                            return {
                              onClick: () => goToChamp(record.slug, record.role)
                            };
                          }}
                        />
                      </WrapperResponsive>
                    )}

                    {champion.lane.slug !== 'dragon' && (
                      <WrapperResponsive sm={'200%'}>
                        <Table
                          locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                          pagination={false}
                          rowKey={record => `${record.role}-${record.slug}`}
                          columns={columnsChampMatchupWith('Dragon')}
                          dataSource={matchups?.with.dragon}
                          loading={isLoadingMatchups}
                          size={'small'}
                          onRow={(record) => {
                            return {
                              onClick: () => goToChamp(record.slug, record.role)
                            };
                          }}
                        />
                      </WrapperResponsive>
                    )}

                    {champion.lane.slug !== 'support' && (
                      <WrapperResponsive sm={'200%'}>
                        <Table
                          locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                          pagination={false}
                          rowKey={record => `${record.role}-${record.slug}`}
                          columns={columnsChampMatchupWith('Soporte')}
                          dataSource={matchups?.with.support}
                          loading={isLoadingMatchups}
                          size={'small'}
                          onRow={(record) => {
                            return {
                              onClick: () => goToChamp(record.slug, record.role)
                            };
                          }}
                        />
                      </WrapperResponsive>
                    )}
                  </CartStyled.Container>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <CartStyled.Container noMinHeight>
                    <CustomTitle $boldItalic level={4}>Jugó contra</CustomTitle>
                    <WrapperResponsive sm={'200%'}>
                      <Table
                        locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                        pagination={false}
                        rowKey={record => `${record.role}-${record.slug}`}
                        columns={columnsChampMatchupAgainst('Baron')}
                        dataSource={matchups?.against.baron}
                        loading={isLoadingMatchups}
                        size={'small'}
                        onRow={(record) => {
                          return {
                            onClick: () => goToChamp(record.slug, record.role)
                          };
                        }}
                      />
                    </WrapperResponsive>

                    <WrapperResponsive sm={'200%'}>
                      <Table
                        locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                        pagination={false}
                        rowKey={record => `${record.role}-${record.slug}`}
                        columns={columnsChampMatchupAgainst('Jungla')}
                        dataSource={matchups?.against.jungle}
                        loading={isLoadingMatchups}
                        size={'small'}
                        onRow={(record) => {
                          return {
                            onClick: () => goToChamp(record.slug, record.role)
                          };
                        }}
                      />
                    </WrapperResponsive>

                    <WrapperResponsive sm={'200%'}>
                      <Table
                        locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                        pagination={false}
                        rowKey={record => `${record.role}-${record.slug}`}
                        columns={columnsChampMatchupAgainst('Mid')}
                        dataSource={matchups?.with.mid}
                        loading={isLoadingMatchups}
                        size={'small'}
                        onRow={(record) => {
                          return {
                            onClick: () => goToChamp(record.slug, record.role)
                          };
                        }}
                      />
                    </WrapperResponsive>

                    <WrapperResponsive sm={'200%'}>
                      <Table
                        locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                        pagination={false}
                        rowKey={record => `${record.role}-${record.slug}`}
                        columns={columnsChampMatchupAgainst('Dragon')}
                        dataSource={matchups?.with.dragon}
                        loading={isLoadingMatchups}
                        size={'small'}
                        onRow={(record) => {
                          return {
                            onClick: () => goToChamp(record.slug, record.role)
                          };
                        }}
                      />
                    </WrapperResponsive>

                    <WrapperResponsive sm={'200%'}>
                      <Table
                        locale={{ emptyText: 'No hay estadísticas para mostrar en esta línea/rol' }} 
                        pagination={false}
                        rowKey={record => `${record.role}-${record.slug}`}
                        columns={columnsChampMatchupAgainst('Soporte')}
                        dataSource={matchups?.with.support}
                        loading={isLoadingMatchups}
                        size={'small'}
                        onRow={(record) => {
                          return {
                            onClick: () => goToChamp(record.slug, record.role)
                          };
                        }}
                      />
                    </WrapperResponsive>
                  </CartStyled.Container>
                </Col>
              </Row>
            </CartStyled.Container>
          </TabPane>
          <TabPane tab="Partidos jugados" key="2">
            <CartStyled.Container noMinHeight>
              <WrapperResponsive sm={'200%'}>
                <Table
                  locale={{ emptyText: 'No hay partidos para mostrar.' }} 
                  pagination={false}
                  rowKey={record => `${record._id}`}
                  columns={columnsChampMatches()}
                  dataSource={champion.matches}
                  loading={isLoadingMatchups}
                  size={'small'}
                  onRow={(record) => {
                    return {
                      onClick: () => goToMatch(record.matchId)
                    };
                  }}
                />
              </WrapperResponsive>
            </CartStyled.Container>
          </TabPane>
          <TabPane tab="Jugadores" key="3">
            <CartStyled.Container noMinHeight>
              <WrapperResponsive sm={'200%'}>
                <Table
                  locale={{ emptyText: 'No hay jugadors que hayan pickeado este campeón.' }} 
                  pagination={false}
                  rowKey={record => `${record._id}`}
                  columns={columnsChampPlayers()}
                  dataSource={players}
                  loading={isLoadingPlayers}
                  size={'small'}
                  onRow={(record) => {
                    return {
                      onClick: () => goToPlayer(record.slug)
                    };
                  }}
                />
              </WrapperResponsive>
            </CartStyled.Container>
          </TabPane>
        </StatsTabs>

      </>
    )}
    </>
  )
}

export default SeasonChampion;