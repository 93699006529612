import React, { FC } from "react";
import { MediaQuery, Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import styled from "styled-components";
import { SeasonMatchPick } from "../domain/matches";
import { ChampThumb, MatchImageWrapper } from "../pages/Match/Match.styled";
import { CustomTitle } from "../../../domain/styles/ApgStyles.styled";
import { Font12, Font16 } from "../../../domain/styles/ApgTypography.styled";

const MatchPickWrapper = styled.div<{ side: string }>`
  display: flex;
  margin-bottom: ${Spacing.Spacing1};

  ${({ side }) => side === 'away' && `
    flex-direction: row-reverse;

    ${MediaQuery.LessThanSmall} {
      align-items: flex-end;
    }
  `}

  ${MediaQuery.LessThanSmall} {
    flex-direction: column !important;
  }
`

export interface MatchPickInterface {
  pick: SeasonMatchPick,
  side: string,
}

const MatchPickData = styled.div<{ side: string }>`
  display: flex;
  flex-direction: column;
  ${Font12}

  .data-box {
    &.large {
      ${Font16}
    }

    ${({ side }) => side === 'home' && `
      text-align: left
    `}

    ${({ side }) => side === 'away' && `
      text-align: right
    `}
  }
`

const MatchPick: FC<MatchPickInterface> = ({ pick, side }: MatchPickInterface) => {
  return (
    <MatchPickWrapper side={side}>
      <MatchImageWrapper right={side === 'home'} left={side === 'away'}>
        <ChampThumb key={pick._id} src={pick.champ.thumbUrl} alt={pick.champ.name} />
      </MatchImageWrapper>
      <div className="d-flex flex-column">
        <CustomTitle 
          side={side}
          $bold 
          level={5}
        >
          {pick.player.inGameName}
        </CustomTitle>
        <MatchPickData side={side}>
          <div className="data-box large">{pick.kills}/{pick.deaths}/{pick.assistants}</div>
          <div className="data-box"><strong>KDA:</strong> {pick.kda}</div>
          <div className="data-box"><strong>KP:</strong> {pick.killParticipation}%</div>
        </MatchPickData>
      </div>
    </MatchPickWrapper>
  )
}

export default MatchPick;