import React, { FC, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Layout, Menu } from 'antd';
import {
  AimOutlined,
  BarChartOutlined,
  HomeOutlined,
  SkinOutlined,
  ThunderboltOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import Section from '@apg-stats/apg-core/lib/ApgSection';
import { Palette } from '@apg-stats/apg-core/lib/css-in-js';
import { TournamentStyled } from './Season.styled';

import { useGlobalAppDispatch } from '../../../../store/app-context';
import { AppActions } from '../../../../store/reducer';
import { useSeasonDispatch } from '../../store/season-context';
import { RequestSeasonActions } from '../../store/actions';
import { SeasonBase } from '../../store/reducer';
import SeasonOverview from '../Overview/Overview';
import SeasonMatches from '../Matches/Matches';
import SeasonMatch from '../Match/Match';
import SeasonStatsTeams from '../Stats/Tabs/Teams';
import SeasonStatsPlayers from '../Stats/Tabs/Players';
import SeasonStatsChampions from '../Stats/Tabs/Champions';
import SeasonChampion from '../Champion/Champion';
import SeasonTeam from '../Team/Team';
import SeasonPlayer from '../Player/Player';

const { Content } = Layout;

interface ParamTypes {
  slug: string
}

const Season: FC = () => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const dispatchApp = useGlobalAppDispatch();
  const dispatchSeason = useSeasonDispatch();
  const [season, setSeason] = useState<SeasonBase>();
  const [selectedKey, setSelectedKey] = useState<string>('1');
  const { slug } = useParams<ParamTypes>();

  const location = useLocation();
  const { pathname } = location;
  const lastElementUrl = pathname.split("/").pop();

  useEffect(() => {
    const getSeason = async (): Promise<void> => {
      try {
        const season = await RequestSeasonActions.getSeason(dispatchApp, dispatchSeason, slug);
        setSeason(season);
      } catch (e) {
        // TODO
      }
    }
    
    getSeason();
  }, [])

  useEffect(() => {
    if (season) {
      const getTeams = async (): Promise<void> => {
        try {
          await RequestSeasonActions.getTeams(dispatchApp, dispatchSeason, season.seasonID);
        } catch (e) {
          // TODO
        }
      }
      
      getTeams();
    }
  }, [season])

  useEffect(() => {
    switch (lastElementUrl) {
      case 'teams': 
        setSelectedKey('2');
        break;
      case 'champions':
        setSelectedKey('3');
        break;
      case 'players':
        setSelectedKey('4');
        break;
      case 'matches':
        setSelectedKey('5');
        break;
      default:
        setSelectedKey('1');
    }
  }, [lastElementUrl])
  
  const isLtLg = useMedia('(max-width: 1024px)');

  const goToUrl = (uri: string, key: string): void => {
    setSelectedKey(key);
    history.push(uri);
  }

  useEffect(() => {
    if (season) {
      dispatchApp({ 
        type: AppActions.SetSecondaryMenuItems,
        payload: {
          secondaryMenuLogo: season.logoUrl,
          showSecondaryMenu: true,
          secondaryMenuItems: [
            {
              id: 'resumen',
              title: 'Resumen',
              href: `${url}`
            },
            {
              id: 'stages',
              title: 'Fases',
              href: `${url}/stages`
            },
            {
              id: 'stats',
              title: 'Estadísticas',
              href: `${url}/stats`
            },
            {
              id: 'matches',
              title: 'Partidos',
              href: `${url}/matches`
            }
          ]
        }
      })
    }
  }, [season])
  
  return (
    <>
    {season && (
      <Layout style={{ backgroundColor: Palette.Gray100, margin: '4rem 0 0' }} className="flex-row">
        <TournamentStyled.ApgSider 
          breakpoint="lg"
          collapsedWidth="0"
          width={'16.875rem'}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[selectedKey]}
            style={{ height: '100%', borderRight: 0 }}
          >
            <div className="d-flex flex-column mb-4 mt-4 align-items-center">
              <img src={season?.logoUrl } style={{ width: isLtLg ? '3rem' : '8rem', borderRadius: '50%' }} />
              {!isLtLg && <h5 className="mt-4 text-center">{season?.name}</h5>}
            </div>
            <Menu.Item key="1" icon={<HomeOutlined />} onClick={() => goToUrl(`${url}`, '1')}>Resumen</Menu.Item>
            <Menu.SubMenu key="stats" icon={<BarChartOutlined />} title="Estadísticas">
              <Menu.Item key="2" icon={<UsergroupAddOutlined />} onClick={() => goToUrl(`${url}/teams`, '3')}>Equipos</Menu.Item>
              <Menu.Item key="3" icon={<AimOutlined />} onClick={() => goToUrl(`${url}/champions`, '3')}>Campeones</Menu.Item>
              <Menu.Item key="4" icon={<SkinOutlined />} onClick={() => goToUrl(`${url}/players`, '4')}>Jugadores</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="5" icon={<ThunderboltOutlined />} onClick={() => goToUrl(`${url}/matches`, '5')}>Partidos</Menu.Item>
          </Menu>
        </TournamentStyled.ApgSider>
        <Section spacing={'sm'} style={{ backgroundColor: Palette.Gray100, marginLeft: isLtLg ? '0' : '16.875rem', width: '100%' }}>
          <Content className="container">
            <Switch>
              <Route exact path={path}>
                <SeasonOverview />
              </Route>
              <Route exact path={`${path}/teams`}>
                <SeasonStatsTeams />
              </Route>
              <Route exact path={`${path}/teams/:teamSlug`}>
                <SeasonTeam />
              </Route>
              <Route exact path={`${path}/players`}>
                <SeasonStatsPlayers />
              </Route>
              <Route exact path={`${path}/players/:playerSlug`}>
                <SeasonPlayer />
              </Route>
              <Route exact path={`${path}/champions`}>
                <SeasonStatsChampions />
              </Route>
              <Route exact path={`${path}/champions/:championSlug`}>
                <SeasonChampion />
              </Route>
              <Route exact path={`${path}/matches`}>
                <SeasonMatches />
              </Route>
              <Route exact path={`${path}/matches/:matchId`}>
                <SeasonMatch />
              </Route>
              <Redirect from="*" to={path} />
            </Switch>
          </Content>
        </Section>
      </Layout>
    )}
    </>
  )
};

export default Season;
