import { Palette, Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import styled from "styled-components";
import { Font12, Font16, FontBold } from "../../../../domain/styles/ApgTypography.styled";


export const MatchSchedule = styled.div`
  align-items: center;
  border-bottom: 1px solid ${Palette.Gray400};
  color: ${Palette.Gray1000};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 ${Spacing.Spacing2};

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${Palette.Gray300};

    img {
      opacity: 1;
    }

    .team-name {
      color: ${Palette.Gray1000}
    }
  }
`

export const MatchScheduleHour = styled.div`
  ${Font12}
  ${FontBold}
`

export const MatchScheduleBest = styled.div`
  ${Font12}
  ${FontBold}
`

export const MatchScheduleTeams = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  position: relative;
  text-align: center;
`

interface MatchScheduleTeamInterface {
  type?: string,
  winner: boolean,
  isComplete: boolean
}

export const MatchScheduleTeam = styled.div<MatchScheduleTeamInterface>`
  align-items: center;
  display: flex;
  width: 45%;

  ${({ type, winner, isComplete }) => type === 'home' && `
    text-align: right;
    justify-content: flex-start;
    flex-direction: row-reverse;

    .team-name {
      color: ${isComplete ? winner ? `${Palette.DarkBlue600} !important` : Palette.Gray600 : Palette.Gray1000};
      padding-right: ${Spacing.Spacing1};
      ${FontBold}
    }
  `}

  ${({ type, winner, isComplete }) => type === 'away' && `
    text-align: left;
    justify-content: flex-start;

    .team-name {
      color: ${isComplete ? winner ? `${Palette.DarkBlue600} !important` : Palette.Gray600 : Palette.Gray1000};
      padding-left: ${Spacing.Spacing1};
      ${FontBold}
    }
  `}
  
  img {
    height: 4rem;
    margin: 1rem 0;
    opacity: ${({ winner, isComplete }) => isComplete ? winner ? 1 : 0.3 : 1};
    width: 4rem;
  }
`

export const MatchScores = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${Palette.Gray800};
  letter-spacing: .15rem;
  width: 5rem;
  ${Font16}
`

interface MatchScoreInterface {
  type: string,
  winner: boolean
}

export const MatchScore = styled.div<MatchScoreInterface>`
  position: relative;

  ${({ type, winner }) => type === 'home' && winner && `
    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.25rem .5rem 0.25rem 0;
      border-color: transparent #007bff transparent transparent;
      content: '';
      left: -2rem;
      padding: 0 0.5rem;
      position: absolute;
      top: 0.5rem;
    }
  `}

  ${({ type, winner }) => type === 'away' && winner && `
    &:after {
      border-style: solid;
      border-width: 0.25rem 0 .25rem .5rem;
      border-color: transparent transparent transparent #007bff;
      content: '';
      padding: 0 0.5rem;
      position: absolute;
      right: -2rem;
      top: 0.5rem;
    }
  `}
`

export const MatchVersus = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${Palette.Gray800};
  letter-spacing: .15rem;
  width: 5rem;
  ${Font16}
`