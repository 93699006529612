import ApgIcon from "@apg-stats/apg-core/lib/ApgIcon";
import { ChampStat } from "@apg-stats/apg-core/lib/champs";
import { Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import IconTurretBlue from "@apg-stats/apg-core/lib/IconTurretBlue";
import IconTurretRed from "@apg-stats/apg-core/lib/IconTurretRed";
import { ColumnsType } from "antd/lib/table/interface";
import { LaneIcons } from "../../../domain/lane-icons";
import { ChampData } from "../../../domain/player.interface";
import { TableStyled } from "../../../domain/styles/Table.styled";

export const champsColumns = (): ColumnsType<ChampStat> => [
  {
    title: 'Role',
    dataIndex: 'role',
    className: 'text-center',
    width: 32,
    render: (text: string, record: ChampStat)  => {
      return (
        LaneIcons(record.role)
      )
    },
  },
  {
    title: 'Campeón',
    dataIndex: 'champ',
    render: (text: string, record: ChampStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: '# Picks',
    dataIndex: 'picks',
    className: 'text-center',
    sorter: true,
    width: 100,
    render: (text: string, record: ChampStat)  => {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <TableStyled.Text>{record.picks}</TableStyled.Text>
        </div>
      )
    },
  },
  {
    title: '# Bans',
    dataIndex: 'bans',
    className: 'text-center',
    sorter: true,
    width: 100,
    showSorterTooltip: {
      title: "Partidas ganadas / Partidas jugadas"
    },
    render: (text: string, record: ChampStat)  => {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <TableStyled.Text>{record.bans}</TableStyled.Text>
        </div>
      )
    },
  },
  {
    title: 'PB%',
    dataIndex: 'winRate',
    className: 'text-center',
    sorter: true,
    width: 100,
    showSorterTooltip: {
      title: "Pick+Ban / Total games"
    },
    render: (text: string, record: ChampStat)  => {
      return `${record.pbRate.toFixed(2)}%`
    },
  },
  {
    title: 'Win Rate',
    dataIndex: 'winRate',
    className: 'text-center',
    sorter: true,
    width: 100,
    showSorterTooltip: {
      title: "Win Rate"
    },
    render: (text: string, record: ChampStat)  => {
      return `${((record.win*100)/record.picks).toFixed(2)}%`
    },
  },
  {
    title: 'PG/PJ',
    dataIndex: 'wins',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Partidas ganadas / Partidas jugadas"
    },
    render: (text: string, record: ChampStat)  => {
      return `${record.win}/${record.matchPlayed}`
    },
  },
  {
    title: 'KDA',
    dataIndex: 'kda',
    className: 'text-center',
    sorter: true,
    width: 100,
    render: (text: string, record: ChampStat)  => {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <TableStyled.Text>{record.kda}</TableStyled.Text>
        </div>
      )
    },
  },
  {
    title: <ApgIcon icon={IconTurretBlue} size={18} alt="Lado azul" />,
    dataIndex: 'blueWins',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Partidas jugadas en lado azul"
    },
    render: (text: string, record: ChampStat)  => {
      return record.blueMatches
    },
  },
  {
    title: <ApgIcon icon={IconTurretRed} size={18} alt="Lado rojo" />,
    dataIndex: 'redWins',
    className: 'text-center',
    sorter: true,
    width: 90,
    showSorterTooltip: {
      title: "Partidas jugadas en lado rojo"
    },
    render: (text: string, record: ChampStat)  => {
      return record.redMatches
    },
  },
];

export const playerChampsColumns = (): ColumnsType<ChampData> => [
  {
    title: 'Campeón',
    dataIndex: 'champ',
    render: (text: string, record: ChampData)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Win rate',
    dataIndex: 'winRate',
    className: 'text-center',
    sorter: true,
    width: 100,
    render: (text: string, record: ChampData)  => {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <TableStyled.Text>{record.winRate}</TableStyled.Text>
        </div>
      )
    },
  },
  {
    title: 'KDA',
    dataIndex: 'kdaFull',
    className: 'text-center',
    sorter: true,
    width: 100,
    render: (text: string, record: ChampData)  => {
      const kda = ((record.kills+record.assistants)/record.deaths).toFixed(2);
      return (
        <div className="d-flex align-items-center justify-content-center">
          <TableStyled.Text>{kda} <span>({record.kills}/{record.deaths}/{record.assistants})</span></TableStyled.Text>
        </div>
      )
    },
  },
  {
    title: 'Partidas Jugadas',
    dataIndex: 'matchPlayed',
    width: 160,
    className: 'text-center',
    sorter: true,
    render: (text: string, record: ChampData)  => {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <TableStyled.Text>{record.matchPlayed}</TableStyled.Text>
        </div>
      )
    },
  }
];