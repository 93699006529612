import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import TournamentList from './pages/List/List';
import Tournament from './pages/Tournament/Tournament';

const Tournaments: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <TournamentList />
      </Route>
      <Route path={`${path}/:slug`}>
        <Tournament />
      </Route>
      <Redirect from="*" to={`${path}`} />
    </Switch>
  )
};

export default Tournaments;
