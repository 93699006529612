import { RequestWildRift } from '../proxy/wildrift';
import { AppActions, DispatchApp } from './reducer';

const { IsFetching, FinishedFetching } = AppActions;

const userProfile = async (dispatchApp: DispatchApp): Promise<void> => {
  dispatchApp({ type: IsFetching });
  try {
    // return await RequestTools.getTools();
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

const getChamps = async (dispatchApp: DispatchApp): Promise<void> => {
  dispatchApp({ type: IsFetching });
  try {
    const champs = await RequestWildRift.getChamps();
    dispatchApp({ type: AppActions.SetChamps, payload: champs });
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

const getCreators = async (dispatchApp: DispatchApp): Promise<void> => {
  dispatchApp({ type: IsFetching });
  try {
    const creators = await RequestWildRift.getCreators();
    dispatchApp({ type: AppActions.SetCreators, payload: creators });
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

const getTeams = async (dispatchApp: DispatchApp): Promise<void> => {
  dispatchApp({ type: IsFetching });
  try {
    const Teams = await RequestWildRift.getTeams();
    dispatchApp({ type: AppActions.SetTeams, payload: Teams });
  } finally {
    dispatchApp({ type: FinishedFetching });
  }
};

export const RequestGlobalApiActions = {
  userProfile,
  getChamps,
  getCreators,
  getTeams
};
