import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Palette, Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import { Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { ChampByPlayer, ChampMatchDetail, ChampMatchupsChamp } from "../../../domain/champion.interface";
import { LaneIcons } from "../../../domain/lane-icons";
import { TableStyled } from "../../../domain/styles/Table.styled";
import { TeamPlayerStats } from "../../../domain/team.interface";
import { SeasonPickBan } from "./season.interfaces";

export const columnsPick = (): ColumnsType<SeasonPickBan> => [
	{
		title: 'Campeón',
		dataIndex: 'champ',
		render: (text: string, record: SeasonPickBan) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
				</div>
			)
		},
	},
	{
		title: 'Win rate',
		dataIndex: 'winRate',
		className: 'text-center',
		width: 100,
		render: (text: string, record: SeasonPickBan) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{`${record.winRate === 0 ? '0.00' : (Math.round(record.winRate || 1 * 100)).toFixed(2)}%`}</TableStyled.Text>
				</div>
			)
		},
	},
	{
		title: '# Picks',
		dataIndex: 'picks',
		className: 'text-center',
		width: 100,
		render: (text: string, record: SeasonPickBan) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{record.picks}</TableStyled.Text>
				</div>
			)
		},
	}
];

export const columnsBan = (): ColumnsType<SeasonPickBan> => [
	{
		title: 'Campeón',
		dataIndex: 'champ',
		render: (text: string, record: SeasonPickBan) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
				</div>
			)
		},
	},
	{
		title: '# Bans',
		dataIndex: 'bans',
		className: 'text-center',
		width: 100,
		render: (text: string, record: SeasonPickBan) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{record.bans}</TableStyled.Text>
				</div>
			)
		},
	}
];

export const columnsChampMatchupWith = (role: string): ColumnsType<ChampMatchupsChamp> => [
	{
		title: role,
		dataIndex: role,
		render: (text: string, record: ChampMatchupsChamp) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
				</div>
			)
		},
	},
	{
		title: 'PG/PJ',
		dataIndex: 'wins',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "Partidas ganadas / Partidas jugadas"
		},
		render: (text: string, record: ChampMatchupsChamp) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{`${record.win}/${record.matchPlayed}`}</TableStyled.Text>
				</div>
			)
		},
	}
];

export const columnsChampMatchupAgainst = (role: string): ColumnsType<ChampMatchupsChamp> => [
	{
		title: role,
		dataIndex: role,
		render: (text: string, record: ChampMatchupsChamp) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>{record.name}</TableStyled.TextCaption>
				</div>
			)
		},
	},
	{
		title: 'PP/PJ',
		dataIndex: 'wins',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "Partidas perdidas / Partidas jugadas"
		},
		render: (text: string, record: ChampMatchupsChamp) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{`${record.win}/${record.matchPlayed}`}</TableStyled.Text>
				</div>
			)
		},
	}
];

export const columnsChampMatches = (): ColumnsType<ChampMatchDetail> => [
	{
		title: '',
		dataIndex: 'team1',
		width: 250,
		render: (text: string, record: ChampMatchDetail) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.home.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>{record.home.name}</TableStyled.TextCaption>
					{record.home.picked && (
						<Tooltip title="Este equipo lo pickeo">
							<CheckCircleOutlined style={{ fontSize: '1rem', marginLeft: '0.5rem', color: Palette.Success }} />
						</Tooltip>
					)}
				</div>
			)
		},
	},
	{
		title: '',
		dataIndex: 'team2',
		width: 250,
		render: (text: string, record: ChampMatchDetail) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.away.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>{record.away.name}</TableStyled.TextCaption>
					{record.away.picked && (
						<Tooltip title="Este equipo lo pickeo">
							<CheckCircleOutlined style={{ fontSize: '1rem', marginLeft: '0.5rem', color: Palette.Success }} />
						</Tooltip>
					)}
				</div>
			)
		},
	},
	{
		title: 'Partida #',
		dataIndex: 'setOrder',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "# de partida en la serie"
		},
		render: (text: string, record: ChampMatchDetail) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{record.setOrder}</TableStyled.Text>
				</div>
			)
		},
	},
	{
		title: 'Resultado',
		dataIndex: 'result',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "Resultado de la partida"
		},
		render: (text: string, record: ChampMatchDetail) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>
						{record.winner ?
							<CheckCircleOutlined style={{ fontSize: '1.5rem', color: Palette.Success }} /> :
							<CloseCircleOutlined style={{ fontSize: '1.5rem', color: Palette.Danger }} />
						}
					</TableStyled.Text>
				</div>
			)
		},
	}
];

export const columnsChampPlayers = (): ColumnsType<ChampByPlayer> => [
	{
		title: 'Jugador',
		dataIndex: 'player',
		width: 150,
		render: (text: string, record: ChampByPlayer) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.profilePic} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>
						<p>{record.name}</p>
            <span>{record.team.name}</span>
					</TableStyled.TextCaption>
				</div>
			)
		},
	},
	{
		title: 'K/D/A',
		dataIndex: 'kda',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "Kills / Deaths / Assistants"
		},
		render: (text: string, record: ChampByPlayer) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{`${record.kills}/${record.deaths}/${record.assistants}`}</TableStyled.Text>
				</div>
			)
		},
	},
	{
		title: 'PP/PJ',
		dataIndex: 'wins',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "Partidas perdidas / Partidas jugadas"
		},
		render: (text: string, record: ChampByPlayer) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{`${record.wins}/${record.matchPlayed}`}</TableStyled.Text>
				</div>
			)
		},
	}
];

export const columnsTeamPlayers = (): ColumnsType<TeamPlayerStats> => [
	{
		title: 'Jugador',
		dataIndex: 'player',
		width: 150,
		render: (text: string, record: TeamPlayerStats) => {
			return (
				<div className="d-flex align-items-center">
					<TableStyled.Image src={record.profilePic} style={{ marginRight: Spacing.Spacing1 }} />
					<TableStyled.TextCaption>
						<p>{record.name}</p>
            <span>{record.team.name}</span>
					</TableStyled.TextCaption>
				</div>
			)
		},
	},
	{
    title: 'Role',
    dataIndex: 'role',
    width: 250,
    render: (text: string, record: TeamPlayerStats)  => {
      return (
				<div className="d-flex align-items-center">
					{record.role.slug ? LaneIcons(record.role.slug) : ''}
				</div>
			)
    },
  },
	{
    title: 'Main pick',
    dataIndex: 'mostPickedChamp',
    width: 250,
    render: (text: string, record: TeamPlayerStats)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.mostPickedChamp.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>
            <p>{record.mostPickedChamp.name}</p>
            <span><b>WR:</b> {record.mostPickedChamp.winRate}% / PG/PJ: {record.mostPickedChamp.wins}/{record.mostPickedChamp.picks}</span>
          </TableStyled.TextCaption>
        </div>
      )
    },
  },
	{
		title: 'KDA',
		dataIndex: 'kda',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "KDA Promedio"
		},
		render: (text: string, record: TeamPlayerStats) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{record.kda}</TableStyled.Text>
				</div>
			)
		},
	},
	{
		title: 'KP',
		dataIndex: 'killParticipation',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "Participación en muertes"
		},
		render: (text: string, record: TeamPlayerStats) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{record.killParticipation}%</TableStyled.Text>
				</div>
			)
		},
	},
	{
		title: 'K/D/A',
		dataIndex: 'kda',
		className: 'text-center',
		width: 90,
		showSorterTooltip: {
			title: "Kills / Deaths / Assistants"
		},
		render: (text: string, record: TeamPlayerStats) => {
			return (
				<div className="d-flex align-items-center justify-content-center">
					<TableStyled.Text>{`${record.kills}/${record.deaths}/${record.assistants}`}</TableStyled.Text>
				</div>
			)
		},
	},
];