import dateFormat from 'dateformat';

export const dateFormatLocale = (): void => {
  dateFormat.i18n = {
    dayNames: [
      "Dom",
      "Lun",
      "Mar",
      "Mie",
      "Jue",
      "Vie",
      "Sab",
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    monthNames: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Oct",
      "Nov",
      "Dic",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Setiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
  };
}

export const getDayNameLocal = (dateFormat: string ): string => {
  if (dateFormat.includes('Yesterday')) {
    return dateFormat.replace('Yesterday', 'Ayer');
  }

  if (dateFormat.includes('Tomorrow')) {
    return dateFormat.replace('Tomorrow', 'Mañana');
  }

  if (dateFormat.includes('Today')) {
    return dateFormat.replace('Today', 'Hoy');
  }

  return dateFormat
}

export const isTodayOrFuture = (dateFormat: string ): boolean => {
  console.log(new Date().setHours(0,0,0,0) - new Date(dateFormat).setHours(0,0,0,0))
  return new Date(dateFormat).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)
}