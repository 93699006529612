import React, { FC, useEffect, useState } from 'react';
import { Layout, Table } from 'antd';
import heroBanner from '../../../../assets/images/herobanner-072021.jpg'
import { HeroBannerStyled } from '../../../../domain/styles/HeroBanner.styled';
import { AppActions } from '../../../../store/reducer';
import { RequestWildRift } from '../../../../proxy/wildrift';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { TableStyled } from '../../../../domain/styles/Table.styled';

import Section from '@apg-stats/apg-core/lib/ApgSection';
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import { TournamentItem, TournamentPaginate } from '@apg-stats/apg-core/lib/tournaments';
import { Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import dateFormat from 'dateformat';
import { CartStyled } from '../../../../domain/styles/Card.styled';
import { WrapperResponsive } from '../../../../domain/styles/ApgStyles.styled';
import { useHistory } from 'react-router-dom';
import useWindowSize from '@apg-stats/apg-core/lib/useWindowSize';

const { Content } = Layout;

const TournamentList: FC = () => {
  const history = useHistory();
  const dispatchApp = useGlobalAppDispatch();
  const [tournaments, setTournaments] = useState<TournamentPaginate>()
  const title = "Torneos";
  const { isLtMd } = useWindowSize();

  useEffect(() => {
    const getStatsByLane = async () => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const response = await RequestWildRift.getTournaments();
        setTournaments(response)
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    };

    getStatsByLane();
  }, [])

  const goToUrl = (uri: string): void => {
    history.push(uri);
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      width: isLtMd ? 200 : '',
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center">
            <TableStyled.Image src={record.logoUrl} style={{ marginRight: Spacing.Spacing1 }} />
            <TableStyled.TextCaption>{record.name} - {record.season}</TableStyled.TextCaption>
          </div>
        )
      },
    },
    {
      title: 'Fechas',
      dataIndex: 'dates',
      className: 'text-center',
      width: 150,
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <TableStyled.Text>{dateFormat(record.startTime, 'shortDate')} - {dateFormat(record.endTime, 'shortDate')}</TableStyled.Text>
          </div>
        )
      },
    },
    {
      title: 'Prizepool',
      dataIndex: 'prizepool',
      className: 'text-center',
      width: 100,
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <TableStyled.Text>{record.prizepool}</TableStyled.Text>
          </div>
        )
      },
    },
    {
      title: 'Ubicación',
      dataIndex: 'location',
      className: 'text-center',
      width: 100,
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {record.countryCode}
          </div>
        )
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      className: 'text-center',
      width: 100,
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <TableStyled.Text>{record.type}</TableStyled.Text>
          </div>
        )
      },
    },
    {
      title: 'Ganador',
      dataIndex: 'winner',
      width: 200,
      className: 'text-center',
      render: (text: string, record: TournamentItem)  => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {record.teamWinner.logo && <TableStyled.Image src={record.teamWinner.logo} style={{ marginRight: Spacing.Spacing1 }} />}
            <TableStyled.Text>{record.teamWinner.name}</TableStyled.Text>
          </div>
        )
      },
    }
  ];
  
  return (
    <>
      <HeroBannerStyled.Page style={{ backgroundImage: `url(${heroBanner})` }}>
        <HeroBannerStyled.Title>{title}</HeroBannerStyled.Title>
      </HeroBannerStyled.Page>
      <Section spacing={'sm'}>
        <Content className="container">
          <CartStyled.Container>
            <WrapperResponsive>
              <Table
                onRow={(record) => {
                  return {
                    onClick: () => goToUrl(record.uri)
                  };
                }}
                locale={{ emptyText: 'No hay torneos para mostrar' }} 
                pagination={{ hideOnSinglePage: true }}
                rowKey={record => record.uri}
                columns={columns}
                dataSource={tournaments?.items}
              />
            </WrapperResponsive>
          </CartStyled.Container>
        </Content>
      </Section>
      <ApgFooter />
    </>
  )
};

export default TournamentList;
