import React, { FC } from 'react';
import { Layout } from 'antd';
import heroBanner from '../../assets/images/herobanner-062022.jpg'
import wildRift from '../../assets/images/wild-rift-logo.png'
import { DashboardStyled } from './Dashboard.styled';
import { CartStyled } from '../../domain/styles/Card.styled';
import TournamentsTable from './sections/TournamentsTable/TournamentsTable';
import Section from '@apg-stats/apg-core/lib/ApgSection';
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import { ApgPageHeader } from '../../domain/styles/ApgStyles.styled';

const { Content } = Layout;

const Dashboard: FC = () => {
  return (
    <>
      <DashboardStyled.HeroBanner style={{ backgroundImage: `url(${heroBanner})` }}>
        <DashboardStyled.HeroImage>
          <img src={wildRift} style={{ width: '15.875rem' }} />
        </DashboardStyled.HeroImage>
      </DashboardStyled.HeroBanner>
      <Section>
        <Content className="container">
          <ApgPageHeader
            backIcon={false}
            title={'Temporadas'}
          />
          <CartStyled.Container>
            <TournamentsTable />
          </CartStyled.Container>
        </Content>
      </Section>
      <ApgFooter />
    </>
  )
};

export default Dashboard;
