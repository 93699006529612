import { TournamentBase } from '../store/reducer';

export interface RequestError {
  title: string,
  message: string
}

const getError = ({ title, message }: RequestError): RequestError =>  ({ title, message })

const getTournament = async (slug: string): Promise<TournamentBase> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/tournaments/${slug}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

export const RequestTournament = {
  getTournament
}