import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Season from './pages/Season/Season';

const Seasons: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:slug`}>
        <Season />
      </Route>
      <Redirect from="*" to={`${path}/tournaments`} />
    </Switch>
  )
};

export default Seasons;
