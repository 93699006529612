import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row, Tabs, Table } from 'antd';
import { useGlobalAppDispatch } from "../../../../store/app-context";
import { AppActions } from "../../../../store/reducer";
import { useSeasonState } from "../../store/season-context";
import { CartStyled } from "../../../../domain/styles/Card.styled";
import IconKills from '@apg-stats/apg-core/lib/IconKills';
import { Palette } from "@apg-stats/apg-core/lib/css-in-js";
import { FlagOutlined, AimOutlined } from "@ant-design/icons";
import { CustomTitle, WrapperResponsive } from "../../../../domain/styles/ApgStyles.styled";
import { OverviewStatistic } from "../Overview/Overview.styled";
import IconTurret from '@apg-stats/apg-core/lib/IconTurret';
import { ChampThumb } from "./Team.styled";
import { StatsTabs } from "../Stats/Stats.styled";
import { RequestSeasonTeams } from "../../proxy/wildrift-season-teams";
import { TeamPlayerStats, TeamStat } from "../../../../domain/team.interface";
import IconBaron from "@apg-stats/apg-core/lib/IconBaron";
import IconHerald from "@apg-stats/apg-core/lib/IconHerald";
import DrakesTable from "../../../stats/pages/Objetives/Drakes";
import { columnsTeamPlayers } from '../../domain/season.columns';

const { TabPane } = Tabs;

interface ParamTypes {
  teamSlug: string
}

const SeasonTeam: FC = () => {
  const history = useHistory();
  const { teamSlug } = useParams<ParamTypes>();
  const { season } = useSeasonState();
  const dispatchApp = useGlobalAppDispatch();
  const [team, setTeam] = useState<TeamStat>();
  const [players, setPlayers] = useState<TeamPlayerStats[]>();
  const [isLoadingPlayers, setIsLoadingPlayers] = useState<boolean>(false);

  const getSeasonTeamStats = async (): Promise<void> => {
    dispatchApp({ type: AppActions.IsFetching });
    try {
      const champion = await RequestSeasonTeams.getTeamStats(season.slug, teamSlug);
      setTeam(champion);
    } finally {
      dispatchApp({ type: AppActions.FinishedFetching });
    }
  }

  const getSeasonTeamsPlayers = async (): Promise<void> => {
    setIsLoadingPlayers(true);
    try {
      const players = await RequestSeasonTeams.getTeamPlayersStats(season.slug, teamSlug);
      setPlayers(players);
    } finally {
      setIsLoadingPlayers(false);
    }
  }

  useEffect(() => {
    getSeasonTeamStats();
    getSeasonTeamsPlayers();
  }, [teamSlug]);

  const goToPlayer = (playerSlug: string): void => {
    history.push(`/seasons/${season.slug}/players/${playerSlug}`);
  }

  return (
    <>
    {team && (
      <>
        <CartStyled.Container noMinHeight>
          <CustomTitle $boldItalic level={4}>{team.name}</CustomTitle>

          <div className="d-flex align-items-start justify-content-start gap-3 flex-column flex-md-row">

            <div className="champ-avatar position-relative">
              <ChampThumb src={team.logoUrl} alt={team.name} />
            </div>

            <Row gutter={[16, 16]} className="flex-1">
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Partidas Ganadas"
                  value={`${team.wins}/${team.totalMatches}`}
                  prefix={<FlagOutlined style={{ fontSize: '1.5rem', color: Palette.Gray900 }} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Total Kills"
                  value={team.totalKills}
                  prefix={<IconKills style={{ fontSize: '1.5rem', color: Palette.Yellow }} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Torretas destruidas"
                  value={team.totalTurrets}
                  prefix={<IconTurret style={{ fontSize: '1.5rem' , color: Palette.Gray800}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Baron Nashor"
                  value={`${team.baronsWins}/${team.totalBarons}`}
                  prefix={<IconBaron style={{ fontSize: '1.5rem' , color: Palette.Baron}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Heraldo de la Grieta"
                  value={`${team.heraldsWins}/${team.totalHeralds}`}
                  prefix={<IconHerald style={{ fontSize: '1.5rem' , color: Palette.Herald}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Exterminios"
                  value={team.totalExterminations}
                  prefix={<AimOutlined style={{ fontSize: '1.5rem' , color: Palette.SkyBlue}} />} 
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Lado Azul"
                  value={`${team.blueSide.wins}/${team.blueSide.total}`}
                  prefix={<IconTurret style={{ fontSize: '1.5rem' , color: Palette.SkyBlue}} />}
                />
              </Col>
              <Col xs={12} sm={8} md={6} lg={6}>
                <OverviewStatistic 
                  title="Lado Rojo"
                  value={`${team.redSide.wins}/${team.redSide.total}`}
                  prefix={<IconTurret style={{ fontSize: '1.5rem' , color: Palette.Danger}} />}
                />
              </Col>
            </Row>
          </div>
        </CartStyled.Container>

        <StatsTabs defaultActiveKey="1">
          <TabPane tab="Dragones" key="1">
            <CartStyled.Container noMinHeight>
              <DrakesTable drakes={team.drakes} />
            </CartStyled.Container>
          </TabPane>
          <TabPane tab="Jugadores" key="2">
            <CartStyled.Container noMinHeight>
              <WrapperResponsive sm={'200%'}>
                <Table
                  locale={{ emptyText: 'No hay estadísticas de los jugadores aún.' }} 
                  pagination={false}
                  rowKey={record => `${record._id}`}
                  columns={columnsTeamPlayers()}
                  dataSource={players}
                  loading={isLoadingPlayers}
                  size={'small'}
                  onRow={(record) => {
                    return {
                      onClick: () => goToPlayer(record.slug)
                    };
                  }}
                />
              </WrapperResponsive>
            </CartStyled.Container>
          </TabPane>
        </StatsTabs>
      </>
    )}
    </>
  )
}

export default SeasonTeam;