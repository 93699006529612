import { Spacing, Palette, MediaQuery } from "@apg-stats/apg-core/lib/css-in-js"
import { FontBold, Font38, Font20 } from '../../../../domain/styles/ApgTypography.styled';
import styled from "styled-components"
import { Tabs } from "antd";

export const MatchTeams = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  position: relative;
  text-align: center;
`

interface MatchTeamInterface {
  type?: string,
  winner: boolean,
  isComplete: boolean
}

export const MatchTeam = styled.div<MatchTeamInterface>`
  align-items: center;
  display: flex;
  width: 45%;

  ${({ type }) => type === 'home' && `
    text-align: right;
    justify-content: flex-start;
    flex-direction: row-reverse;

    ${MediaQuery.LessThanMedium} {
      flex-direction: column;
    }
  `}

  ${({ type }) => type === 'away' && `
    text-align: left;
    justify-content: flex-start;

    ${MediaQuery.LessThanMedium} {
      flex-direction: column;
    }
  `}

  .team-name {
    color: ${({ winner, isComplete }) => isComplete ? winner ? `${Palette.DarkBlue600} !important` : Palette.Gray1000 : Palette.Gray1000};
    padding-right: ${({ type }) => type === 'home' ? Spacing.Spacing1 : 0 };
    padding-left: ${({ type }) => type === 'away' ? Spacing.Spacing1 : 0 };
    ${FontBold}
    ${Font38}

    ${MediaQuery.LessThanLarge} {
      ${Font20}
    }

    ${MediaQuery.LessThanMedium} {
      padding: 0;
      text-align: center;
    }
  }
  
  img {
    height: 6rem;
    margin: 1rem 0;
    opacity: 1;
    width: 6rem;

    ${MediaQuery.LessThanLarge} {
      height: 4rem;
      width: 4rem;
    }
  }
`

export const MatchScores = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${Palette.Gray800};
  letter-spacing: .15rem;
  width: 10rem;
  ${FontBold}
  ${Font38}

  ${MediaQuery.LessThanMedium} {
    width: 6rem;
  }

  .separator {
    padding: 0 ${Spacing.Spacing1};
  }
`

interface MatchScoreInterface {
  type: string,
  winner: boolean
}

export const MatchScore = styled.div<MatchScoreInterface>`
  position: relative;

  ${({ type, winner }) => type === 'home' && winner && `
    &:after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.5rem 1rem 0.5rem 0;
      border-color: transparent #007bff transparent transparent;
      content: '';
      left: -2.5rem;
      padding: 0 0.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      ${MediaQuery.LessThanMedium} {
        display: none;
      }
    }
  `}

  ${({ type, winner }) => type === 'away' && winner && `
    &:after {
      border-style: solid;
      border-width: 0.5rem 0 .5rem 1rem;
      border-color: transparent transparent transparent #007bff;
      content: '';
      padding: 0 0.5rem;
      position: absolute;
      right: -2.5rem;
      top: 50%;
      transform: translateY(-50%);

      ${MediaQuery.LessThanMedium} {
        display: none;
      }
    }
  `}
`

export const MatchVersus = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${Palette.Gray800};
  letter-spacing: .15rem;
  width: 10rem;
  ${Font38}

  ${MediaQuery.LessThanMedium} {
    width: 6rem;
  }
`

export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav {
    &:before {
      display: none;
    }
  }

  .ant-tabs-nav-wrap {
    background-color: ${Palette.Gray200};
    border-radius: 0.625rem;
    padding: .5rem;
    width: 100%;
  }

  .ant-tabs-nav-list {
    flex: auto;

    .ant-tabs-tab {
      flex: 1 1 0px;
      justify-content: center;
      margin: 0.25rem;
      padding: .5rem 0;
  
      & + .ant-tabs-tab {
        margin: 0 !important;
      }
    }
  }
`

export const MatchSetRow = styled.div<{ last?: boolean, height?: string }>`
  align-items: center;
  border-bottom: 1px solid ${Palette.Gray400};
  display: flex;
  height: ${({ height }) => height ? height : '4rem'};
  justify-content: space-between;
  padding: 0 ${Spacing.Spacing2};

  ${({ last }) => last && `
    border-bottom: 0;
  `}
`

export const MatchImageWrapper = styled.div<{ left?: boolean, right?: boolean }>`
  width: 6rem;

  ${MediaQuery.LessThanMedium} {
    width: 4rem;
  }

  ${({ left }) => left && `
    padding-left: ${Spacing.Spacing1}
  `}

  ${({ right }) => right && `
    padding-right: ${Spacing.Spacing1}
  `}
`

export const ChampAvatar = styled.img<{ left?: boolean, right?: boolean }>`
  border-radius: 0.625rem;
  filter: grayscale(1);
  width: 3.5rem;

  ${MediaQuery.LessThanMedium} {
    width: 2.5rem;
  }

  ${({ left }) => left && `
    margin-left: ${Spacing.Spacing1}
  `}

  ${({ right }) => right && `
    margin-right: ${Spacing.Spacing1}
  `}
`

export const ChampThumb = styled.img`
  border-radius: 0.625rem;
  display: block;
  position: relative;
  width: 8rem;
`

export const ChampRoleImage = styled.img`
  display: block;
  left: .5rem;
  position: absolute;
  top: .5rem;
  width: 2rem;
`