
import { Dispatch } from 'react';
import { AppActions } from '../../../store/reducer';
import { DispatchObject } from '@apg-stats/apg-core/lib/dispatch-object';
import { RequestTournament } from '../proxy/wildrift-tournament';
import { TournamentActions } from './reducer';
import { TournamentBase } from './reducer';

type DispatchApp = Dispatch<DispatchObject<AppActions>>;
type DispatchTournament = Dispatch<DispatchObject<TournamentActions>>;

const getTournament = async (dispatchApp: DispatchApp, dispatchTournament: DispatchTournament, slug: string): Promise<TournamentBase> => {
  dispatchApp({ type: AppActions.IsFetching });
  try {
    const tournament = await RequestTournament.getTournament(slug);
    dispatchTournament({ type: TournamentActions.SetTournament, payload: tournament });
    return tournament;
  } finally {
    dispatchApp({ type: AppActions.FinishedFetching });
  }
};

export const RequestTournamentActions = {
  getTournament
};
