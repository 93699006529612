import { Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import { ColumnsType } from "antd/lib/table/interface";
import { TableStyled } from "../../../domain/styles/Table.styled";
import { PlayerStat } from "../../../domain/player.interface";

export const columnsSeasonPlayer = (): ColumnsType<PlayerStat> => [
  {
    title: 'Nombre',
    dataIndex: 'team',
    render: (text: string, record: PlayerStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.profilePic} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>
            <p>{record.name}</p>
            <span>{record.team.name}</span>
          </TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Main pick',
    dataIndex: 'mostPickedChamp',
    width: 250,
    render: (text: string, record: PlayerStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.mostPickedChamp.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>
            <p>{record.mostPickedChamp.name}</p>
            <span><b>WR:</b> {record.mostPickedChamp.winRate}% / PG/PJ: {record.mostPickedChamp.wins}/{record.mostPickedChamp.matchPlayed}</span>
          </TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Win Rate',
    dataIndex: 'winRate',
    className: 'text-center',
    sorter: true,
    width: 110,
    render: (text: string, record: PlayerStat)  => {
      return `${record.winRate}%`
    },
  },
  {
    title: 'KDA',
    dataIndex: 'kda',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: PlayerStat)  => {
      return (Math.round(record.kda * 100) / 100).toFixed(2);
    },
  },
  {
    title: 'KP%',
    dataIndex: 'killParticipation',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: PlayerStat)  => {
      return `${record.killParticipation}%`;
    },
  },
  {
    title: 'PG/PJ',
    dataIndex: 'wins',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: PlayerStat)  => {
      return `${record.wins}/${record.matchPlayed}`
    },
  }
];

export const columnsStatsPlayers = (): ColumnsType<PlayerStat> => [
  {
    title: 'Nombre',
    dataIndex: 'team',
    render: (text: string, record: PlayerStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.profilePic} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>
            <p>{record.name}</p>
            <span>{record.team.name}</span>
          </TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Main pick',
    dataIndex: 'bestChamp',
    width: 200,
    render: (text: string, record: PlayerStat)  => {
      return (
        <div className="d-flex align-items-center">
          <TableStyled.Image src={record.bestChamp.images.avatarUrl} style={{ marginRight: Spacing.Spacing1 }} />
          <TableStyled.TextCaption>
            <p>{record.bestChamp.name}</p>
            <span>WR: {record.bestChamp.winRate}% | PG/PJ: {record.bestChamp.wins}/{record.bestChamp.matchPlayed}</span>
          </TableStyled.TextCaption>
        </div>
      )
    },
  },
  {
    title: 'Win Rate',
    dataIndex: 'winRate',
    className: 'text-center',
    sorter: true,
    width: 110,
    render: (text: string, record: PlayerStat)  => {
      return `${(Math.round(record.winRate * 100) / 100).toFixed(2)}%`
    },
  },
  {
    title: 'Wins',
    dataIndex: 'wins',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: PlayerStat)  => {
      return record.wins
    },
  },
  {
    title: 'KDA',
    dataIndex: 'kda',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: PlayerStat)  => {
      return (Math.round(record.kda * 100) / 100).toFixed(2);
    },
  },
  {
    title: 'KP%',
    dataIndex: 'killParticipation',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: PlayerStat)  => {
      return (Math.round(record.killParticipation * 100) / 100).toFixed(2);
    },
  },
  {
    title: 'PJ',
    dataIndex: 'matchPlayed',
    className: 'text-center',
    sorter: true,
    width: 90,
    render: (text: string, record: PlayerStat)  => {
      return record.matchPlayed
    },
  }
];

