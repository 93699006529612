import { Palette, Spacing } from "@apg-stats/apg-core/lib/css-in-js";
import styled from "styled-components";

interface ContainerInterface {
  noMinHeight?: boolean;
  padding?: string | number;
}

const Container = styled.div<ContainerInterface>`
  background-color: ${Palette.Gray200};
  border-radius: 0 1rem 0 1rem;
  margin-bottom: ${Spacing.Spacing2};
  min-height: 30rem;
  overflow: hidden;
  padding: ${Spacing.Spacing2} ${Spacing.Spacing2} ${Spacing.Spacing2};

  ${({ noMinHeight }) => noMinHeight && `
    min-height: 0 !important;
  `}

  ${({ padding }) => padding && `
    padding: ${padding} !important;
  `}
`

export const CartStyled = {
  Container
}