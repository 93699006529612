import { Input } from 'antd';
import { MediaQuery, Spacing } from '@apg-stats/apg-core/lib/css-in-js';
import Select from 'react-select';
import styled from "styled-components";

const { Search } = Input;

const FilterSearch = styled(Search)`
  margin-right: ${Spacing.Spacing1};
  button.ant-btn-icon-only {
    font-size: 0rem;
  }
`

const FilterSelect = styled(Select)`
  width: 10rem;
  margin-bottom: ${Spacing.Spacing1};

  ${MediaQuery.LessThanMedium} {
    margin-bottom: ${Spacing.Spacing2};
    width: 100%;
  }
  
  .apg-select__control {
    border-radius: 0.125rem;
    min-height: 2rem;
  }

  .apg-select__value-container {
    padding-bottom: 0;
    padding-top: 0;
  }

  .apg-select__placeholder {
    color: #bfbfbf;
    font-size: 0.875rem;
  }

  .apg-select__placeholder + div {
    padding-bottom: 0;
    padding-top: 0;
  }

  .apg-select__single-value {
    font-size: 0.875rem;
  }

  .apg-select__menu {
    z-index: 9;
  }

  div[class$="indicatorContainer"] {
    padding: .25rem;
  }
`

export const ChampionStyled = {
  FilterSearch,
  FilterSelect
}