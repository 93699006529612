import { ChampFilters, ChampStat } from '@apg-stats/apg-core/lib/champs';
import { ChampByPlayer, ChampionStatDetail, ChampMatchups } from '../../../domain/champion.interface';
import { PlayerFilters, PlayerStatsPaginate } from '../../../domain/player.interface';
import { TeamFilters, TeamStat } from '../../../domain/team.interface';
import { ObjetivesInterface, SeasonStats } from '../../stats/domain/objetive.interface';
import { LastSeason } from '../domain/last-seasons';
import { SeasonMatchDetail, SeasonMatchesByDate } from '../domain/matches';
import { SeasonPicksBans } from '../domain/season.interfaces';
import { SeasonStage } from '../domain/stages';
import { SeasonBase, SeasonTeam } from '../store/reducer';

export interface RequestError {
  title: string,
  message: string
}

const getError = ({ title, message }: RequestError): RequestError =>  ({ title, message })

const getSeason = async (slug: string): Promise<SeasonBase> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${slug}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonStats = async (slug: string): Promise<SeasonStats> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${slug}/stats`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getTeams = async (seasonId: string): Promise<SeasonTeam[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonId}/teams`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonPickByLane = async (seasonId: string): Promise<ChampStat[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonId}/stats/pick-by-lane`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonTeams = async (slug: string, filters: TeamFilters): Promise<TeamStat[]> => {
  const winRate = filters.winRate ? `&winRate=${filters.winRate}` : '';
  const teamId = filters.teamId ? `&teamId=${filters.teamId}` : '';
  const totalKills = filters.totalKills ? `&totalKills=${filters.totalKills}` : '';
  const blueWins = filters.blueWins ? `&blueWins=${filters.blueWins}` : '';
  const redWins = filters.redWins ? `&redWins=${filters.redWins}` : '';
  const field = filters.field ? `&field=${filters.field}` : '';
  const order = filters.order ? `&order=${filters.order}` : '';

  const concatFilters = `${teamId}${winRate}${totalKills}${blueWins}${redWins}${field}${order}`;

  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${slug}/teams/stats?page=${filters.page}&limit=${filters.limit}&top=${filters.top}${concatFilters}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getLastSeasons = async (seasonId: string, leagueId: string): Promise<LastSeason[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonId}/last?leagueId=${leagueId}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonChampStats = async (slug: string, filters: ChampFilters): Promise<ChampStat[]> => {
  const searchLane = filters.laneId ? `&laneId=${filters.laneId}` : '';
  const searchChamp = filters.champId ? `&champId=${filters.champId}` : '';
  const field = filters.field ? `&field=${filters.field}` : '';
  const order = filters.order ? `&order=${filters.order}` : '';

  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${slug}/champions?page=${filters.page}&limit=${filters.limit}${searchLane}${searchChamp}${field}${order}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonPlayerStats = async (slug: string, filters: PlayerFilters): Promise<PlayerStatsPaginate> => {
  const searchChamp = filters.champId ? `&champId=${filters.champId}` : '';
  const searchPlayer = filters.playerId ? `&playerId=${filters.playerId}` : '';
  const searchTeam = filters.teamId ? `&teamId=${filters.teamId}` : '';
  const field = filters.field ? `&field=${filters.field}` : '';
  const order = filters.order ? `&order=${filters.order}` : '';

  const filterConcat = `${searchPlayer}${searchChamp}${searchTeam}${field}${order}`;

  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${slug}/players?page=${filters.page}&limit=${filters.limit}${filterConcat}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonObjetivesStats = async (seasonId: string): Promise<ObjetivesInterface> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonId}/stats/objetives`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonPicksBans = async (slug: string): Promise<SeasonPicksBans> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${slug}/picks-bans`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonMatches = async (seasonId: string): Promise<SeasonMatchesByDate[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonId}/matches`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonMatch = async (seasonId: string, matchId: string): Promise<SeasonMatchDetail> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonId}/matches/${matchId}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonStages = async (seasonId: string): Promise<SeasonStage[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonId}/stages`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonChampionDetail = async (seasonSlug: string, championId: string, role?: string): Promise<ChampionStatDetail> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonSlug}/champions/${championId}${role ? `?role=${role}` : ''}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonChampionMatchups = async (seasonSlug: string, championId: string, role?: string): Promise<ChampMatchups> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonSlug}/champions/${championId}/matchups${role ? `?role=${role}` : ''}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getSeasonChampionPlayers = async (seasonSlug: string, championId: string, role?: string): Promise<ChampByPlayer[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/seasons/${seasonSlug}/champions/${championId}/players${role ? `?role=${role}` : ''}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

export const RequestSeason = {
  getSeason,
  getSeasonStats,
  getTeams,
  getSeasonPickByLane,
  getSeasonTeams,
  getLastSeasons,
  getSeasonChampStats,
  getSeasonPlayerStats,
  getSeasonObjetivesStats,
  getSeasonMatches,
  getSeasonMatch,
  getSeasonStages,
  getSeasonPicksBans,
  getSeasonChampionDetail,
  getSeasonChampionMatchups,
  getSeasonChampionPlayers
}