import { Champ, ChampFilters, ChampPaginate, ChampStat } from "@apg-stats/apg-core/lib/champs";
import { Creator } from "@apg-stats/apg-core/lib/creators";
import { ProBuild } from "@apg-stats/apg-core/lib/probuilds";
import { TournamentPaginate } from "@apg-stats/apg-core/lib/tournaments";
import { LaneItem } from "../domain/lanes";
import { TeamBase } from "../domain/team.interface";

export interface RequestError {
  title: string,
  message: string
}

const getError = ({ title, message }: RequestError): RequestError =>  ({ title, message })

const getLanes = async (): Promise<LaneItem[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/items?type=LANE`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getChamps = async (): Promise<Champ[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/champs`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getCreators = async (): Promise<Creator[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/creators`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getChampStats = async (filters: ChampFilters): Promise<ChampPaginate> => {
  const searchLane = filters.laneId ? `&laneId=${filters.laneId}` : '';
  const searchChamp = filters.champId ? `&champId=${filters.champId}` : '';
  const field = filters.field ? `&field=${filters.field}` : '';
  const order = filters.order ? `&order=${filters.order}` : '';

  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/stats/champions?page=${filters.page}&limit=${filters.limit}${searchLane}${searchChamp}${field}${order}`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getStatsByLane = async (): Promise<ChampStat[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/stats/pick-by-lane`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getTournaments = async (): Promise<TournamentPaginate> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/tournaments`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getRandomProBuilds = async (token?: string): Promise<ProBuild[]> => {
  let headers = {}

  if (token) {
    headers = {
      'Authorization': token
    }
  }
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/probuilds-random`, {
    method: 'GET',
    headers
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

const getTeams = async (): Promise<TeamBase[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/wildrift/teams`, {
    method: 'GET'
  });

  const result = await response.json();

  if (!response.ok) {
    throw getError(result);
  }

  return result;
}

export const RequestWildRift = {
  getLanes,
  getChamps,
  getChampStats,
  getStatsByLane,
  getRandomProBuilds,
  getTournaments,
  getCreators,
  getTeams
}