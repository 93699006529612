import { Palette } from "@apg-stats/apg-core/lib/css-in-js"
import styled from "styled-components"
import { Font12, Font14, FontBold } from "./ApgTypography.styled"


const Image = styled.img`
  border-radius: 0 0.25rem 0 0.25rem;
  height: 2rem;
  width: 2rem;
`

const TextCaption = styled.div`
  display: flex;
  flex-direction: column;
  ${FontBold}
  ${Font14}

  p {
    margin-bottom: 0;
  }

  span {
    font-weight: 300;
    line-height: 1 !important;
    ${Font12}
  }
`

const Text = styled.div`
  ${Font14}

  span {
    color: ${Palette.Gray700};
    display: block;
    font-size: .70rem;
    font-weight: 300;
    line-height: 1 !important;
  }
`

export const TableStyled = {
  Image,
  TextCaption,
  Text
}