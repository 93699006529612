import React, { FC, useEffect, useState } from 'react';
import { useSeasonState } from '../../store/season-context';
import { CartStyled } from '../../../../domain/styles/Card.styled';
import { CustomTitle } from '../../../../domain/styles/ApgStyles.styled';
import { useGlobalAppDispatch } from '../../../../store/app-context';
import { SeasonMatch, SeasonMatchesByDate } from '../../domain/matches';
import dateFormat from 'dateformat';
import { dateFormatLocale, getDayNameLocal } from '../../../../domain/date-format';
import { scroller } from 'react-scroll'
import { AppActions } from '../../../../store/reducer';
import { RequestSeason } from '../../proxy/wildrift-season';
import closestTo from 'date-fns/closestTo';
import isEqual from 'date-fns/isEqual';
import isFuture from 'date-fns/isFuture';
import { MatchSchedule, MatchScheduleBest, MatchScheduleHour, MatchScheduleTeam, MatchScheduleTeams, MatchScore, MatchScores, MatchVersus } from './Matches.styled';
import { useHistory, useRouteMatch } from 'react-router-dom';

const SeasonMatches: FC = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { season } = useSeasonState();
  const dispatchApp = useGlobalAppDispatch();
  const [seasonMatches, setSeasonMatches] = useState<SeasonMatchesByDate[]>([]);
  const [allDates, setAllDates] = useState<Date[]>([]);

  useEffect(() => {
    const getSeasonMatches = async (): Promise<void> => {
      dispatchApp({ type: AppActions.IsFetching });
      try {
        const matches = await RequestSeason.getSeasonMatches(season.seasonID);
        setSeasonMatches(matches);
        getOnlyDates(matches);
      } finally {
        dispatchApp({ type: AppActions.FinishedFetching });
      }
    }
    
    getSeasonMatches();
    dateFormatLocale()
  }, [])

  useEffect(() => {
    if (seasonMatches && allDates) {
      scroller.scrollTo('back-to-the-future', {
        duration: 0,
        delay: 0,
        offset: -80,
        smooth: 'easeInOutQuart'
      })
    }
  }, [seasonMatches, allDates])

  
  const goToUrl = (path: string): void => {
    history.push(path);
  }

  const getOnlyDates = (matches: SeasonMatchesByDate[]) => {
    const dates: Date[] = matches.map(({ _id }: SeasonMatchesByDate) => new Date(_id));
    setAllDates(dates)
  }

  const isClosestDate = (date: string): boolean => {
    const closest = closestTo(new Date(), allDates);
    const isSameDate = isEqual(closest || new Date(), new Date(date));
    return isSameDate;
  }
  
  return (
    <>
      {seasonMatches.length > 0 && seasonMatches.map(({ _id, matches }: SeasonMatchesByDate) => (
        <>
          {isClosestDate(_id) && (
            <div id="back-to-the-future" className="back-to-the-future">
              {isFuture(new Date(_id)) ? <CustomTitle $boldItalic level={3}>Próximas fechas</CustomTitle> : ''}
            </div>
          )}
          <CustomTitle style={{ padding: 0 }} $bold level={5}>{getDayNameLocal(dateFormat(_id, 'DDDD'))} <span>{dateFormat(_id, '- dd "de" mmmm')}</span></CustomTitle>
          <CartStyled.Container padding={'0rem'} noMinHeight key={_id}>
            {matches && matches.map((match: SeasonMatch) => {
              return (
                <a href="javascript:void(0)" role='button' key={match._id} onClick={() => goToUrl(`${url}/${match._id}`)}>
                  <MatchSchedule>
                    <MatchScheduleHour>{dateFormat(match.startTime, 'HH:MM')}</MatchScheduleHour>
                    <MatchScheduleTeams>
                      <MatchScheduleTeam type="home" winner={match.home.winner} isComplete={match.isComplete}>
                        <img src={match.home.logoUrl} alt={match.home.name} />
                        <span className="team-name">{match.home.name}</span>
                      </MatchScheduleTeam>
                      <div className="divider d-flex align-items-center">
                        {match.isComplete && (
                          <MatchScores>
                            <MatchScore type="home" winner={match.home.winner}>{match.home.score}</MatchScore>
                            <div className="separator">-</div>
                            <MatchScore type="away" winner={match.away.winner}>{match.away.score}</MatchScore>
                          </MatchScores>
                        )}
                        {!match.isComplete && (
                          <MatchVersus>vs</MatchVersus>
                        )}
                      </div>
                      <MatchScheduleTeam type="away" winner={match.away.winner} isComplete={match.isComplete}>
                        <img src={match.away.logoUrl} alt={match.away.name} />
                        <span className="team-name">{match.away.name}</span>
                      </MatchScheduleTeam>
                    </MatchScheduleTeams>
                    <MatchScheduleBest>Mejor de {match.matchSets}</MatchScheduleBest>
                  </MatchSchedule>
                </a>
              )
            })}
        </CartStyled.Container>
        </>
      ))}
    </>
  )
};

export default SeasonMatches;