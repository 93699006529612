import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Layout, Typography } from 'antd';
import heroBanner from '../../assets/images/herobanner-072021.jpg'
import { HeroBannerStyled } from '../../domain/styles/HeroBanner.styled';
import { ApgPageHeader } from '../../domain/styles/ApgStyles.styled';
import ProBuilds from '../dashboard/sections/ProBuilds/ProBuilds';
import Champions from './pages/Champions/Champions';
import Objetives from './pages/Objetives/Objetives';
import Section from '@apg-stats/apg-core/lib/ApgSection';
import ApgFooter from '@apg-stats/apg-core/lib/ApgFooter';
import { Palette } from '@apg-stats/apg-core/lib/css-in-js';

const { Link } = Typography;
const { Content } = Layout;

const Stats: FC = () => {
  const { path } = useRouteMatch();
  const title = "Estadísticas";

  return (
    <>
      <HeroBannerStyled.Page style={{ backgroundImage: `url(${heroBanner})` }}>
        <HeroBannerStyled.Title>{title}</HeroBannerStyled.Title>
      </HeroBannerStyled.Page>
      <Section spacing={'sm'}>
        <Content className="container">
          <Switch>
            <Route exact path={`${path}/champions`}>
              <Champions />
            </Route>
            <Route exact path={`${path}/objetives`}>
              <Objetives />
            </Route>
            <Redirect from="*" to={`${path}/champions`} />
          </Switch>
        </Content>
      </Section>
      <Section style={{ backgroundColor: Palette.DarkBlue600 }} showVolt showVoltBottom={false} spacing={'xl'}>
        <Content className="container">
          <ApgPageHeader
            light={true}
            backIcon={false}
            title={'Pro Builds'}
            extra={
              <Link href='/probuilds'>
                VER MÁS BUILDS
              </Link>
            }
          />

          <ProBuilds />
        </Content>
      </Section>
      <ApgFooter />
    </>
  )
};

export default Stats;
